import RestService from './RestService';
import DonorType from '../enums/DonorType';
import { getCms } from './CmsService';

export default class GTMService {

    static _instance: GTMService;

    static instance = (): GTMService => {
        if(!GTMService._instance) {
            GTMService._instance = new GTMService();
        }
        return GTMService._instance;
    }
    
    _GTMKey : any | null;

    get GTMKey() : any | null {
        return this._GTMKey;
    }

    set GTMKey(value:  any | null) {
        this._GTMKey = value;
    }

    constructor () {
        this._GTMKey = null;
        this.init();
    }

    init = async () => {
        this.GTMKey = await (await RestService.getSetting("DonorApp.Analytics.GTMCode", null)).SettingValue;
        //For local testing
        //this.GTMKey = "GTM-WP562T4";

        this.generateScriptToHead();
        this.generateScriptToBody();
    }

    /**
    * Generate scripts to head 
    */
    generateScriptToHead = () => {
        if(this.GTMKey) {
            let GTMTag = document.createElement('script');
            GTMTag.innerHTML = `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${this.GTMKey}');
            `;
            
            /* let dataLayer = document.createElement('script');
            dataLayer.innerHTML = `
                window.dataLayer = window.dataLayer || [];
            `; */
            
            document.head.prepend(GTMTag);
            //document.head.prepend(dataLayer); 
        }
    }

    /**
    * Generate script to body
    */
    generateScriptToBody = () => {
        if(this.GTMKey) {
            const iframe = document.createElement('iframe');
            iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.GTMKey}`;
            iframe.height = '0';
            iframe.width = '0';
            iframe.style.display = 'none';
            iframe.style.visibility = 'hidden';

            const ns = document.createElement('noscript');

            ns.appendChild(iframe);
            
            document.body.prepend(ns);
        }
    }

    // Push new registration event to GTM data layer
    static registrationEvent = (centerName: string | null, donorType: DonorType = DonorType.FirstTimer) => {
        let dataLayer = (window as any).dataLayer;
        dataLayer?.push({ 
            'event' : 'Registration',
            'button': {
                'key' : 'Register',
                'localisation' : getCms('registration.register')
            },
            'place' : centerName,
            'donor-type': `${donorType.value} (${getCms(donorType.translationKey)})`
        });
    }

    // Push new reservation event to GTM data layer
    static createReservationEvent = (centerName: string | null, isFirstTimer: boolean) => {
        let dataLayer = (window as any).dataLayer;
        let donorType = isFirstTimer ? DonorType.FirstTimer : DonorType.AlreadyDonor;
        dataLayer?.push({ 
            'event' : 'CreateReservation',
            'button': {
                'key' : 'CreateNewReservation',
                'localisation' : getCms('reservation.createNewReservation')
            },
            'place' : centerName,
            'donor-type': `${donorType.value} (${getCms(donorType.translationKey)})`
        });
    }

    // Push cancel reservation event to GTM data layer
    static cancelReservationEvent = (centerName: string | null) => {
        let dataLayer = (window as any).dataLayer;
        dataLayer?.push({ 
            'event' : 'CancelReservation',
            'button': {
                'key' : 'CancelReservation',
                'localisation' : getCms('dashboard.nextReservation.cancelAlertConfirm')
            },
            'place' : centerName
        });
    }
}