import RestService from './RestService';
import { initStorage } from './UserService';
import { initI18n } from './I18n';
import GTMService from './GTMService';
import { initCms } from './CmsService';
import { initPlatform } from './PlastformService';

export async function initApp() {
    await initPlatform();
    RestService.init();
    await initStorage();
    await initI18n();
    await initCms();
    GTMService.instance();
}