import { Device } from '@capacitor/device';
import { LanguageDetectorAsyncModule, Services, InitOptions } from "i18next";

interface Detector {
    name: string;
    lookup: Function;
    cacheUserLanguage?: Function;
}

interface Detectors {
    [index: string]: Detector;
}

class LanguageDetector implements LanguageDetectorAsyncModule {
    static type:'languageDetector' = 'languageDetector';
    async: true = true;
    detectors: Detectors;
    services?: Services;
    options: any;
    i18nOptions?: InitOptions;
    hasSessionStorageSupport?: boolean | null;
    hasLocalStorageSupport?: boolean | null;
    detectionOrder: string[] | null;
    
    constructor(services?: Services, detectorOptions?: object, i18nextOptions?: InitOptions) {
        // Constructor is called without arguments by i18next
        this.type = 'languageDetector';
        this.async = true;
        this.detectors = {};
        this.hasSessionStorageSupport = null;
        this.hasLocalStorageSupport = null;
        this.detectionOrder = null;
    }
    type: 'languageDetector';

    init(services?: Services, detectorOptions?: object, i18nextOptions?: InitOptions) {
        this.services = services;
        this.options = detectorOptions;
        this.i18nOptions = i18nextOptions;

        this.options = this.defaults(detectorOptions, i18nextOptions || {}, this.getDefaults()); // backwards compatibility

        if (this.options.lookupFromUrlIndex) this.options.lookupFromPathIndex = this.options.lookupFromUrlIndex;

        if (Object.keys(i18nextOptions ?? {}).length && i18nextOptions?.debug === true) {
            this.options.debug = true;
        }

        this.addDetector(this.navigator);
        this.addDetector(this.sessionStorage);
        this.addDetector(this.localStorage);
        this.addDetector(this.device);
        this.addDetector(this.htmlTag);
        this.addDetector(this.subdomain);
        this.addDetector(this.querystring);
        this.addDetector(this.cookie);
    }

    addDetector(detector: Detector):void {
        this.detectors[detector.name] = detector;
    }
    // Used by i18next to confirm finished async callback
    detectionFinishedCallback(lng: string[]):void {
        if (this.options.debug) console.info(`Language detection finished, found languages: `, lng);
    }

    detect(callback: Function = this.detectionFinishedCallback):Promise<any> {
        if (!this.detectionOrder) this.detectionOrder = this.options.order;

        return this.detectAsync().then(res => {
            callback(res);
        });
    }
  
    async detectAsync():Promise<string | string[] | null> {
        let detected: string[] = [];

        await Promise.all(this.detectionOrder!.map(async detectorName => {
            if (this.detectors[detectorName]) {
                let lookup = await this.detectors[detectorName].lookup.bind(this)(this.options);
                if (lookup && typeof lookup === 'string') lookup = [lookup];
                if (lookup) detected = detected.concat(lookup);
                if (this.options.debug) console.info(`Detector '${detectorName}' detected ${lookup}, total detected languages: ${detected}`);
            }
        }));

        if (this.services?.languageUtils.getBestMatchFromCodes) return detected; // new i18next v19.5.0

        return detected.length > 0 ? detected[0] : null; // a little backward compatibility
    }

    getDefaults() {
        return {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            // cache user language
            caches: ['localStorage'],
            excludeCacheFor: ['cimode'] //cookieMinutes: 10,
            //cookieDomain: 'myDomain'
        };
    }

    defaults(...obj: any): any {
        let arr: any = [];
        arr.forEach.call(arr.slice.call(arguments), (source: any) => {
            if (source) {
                for (let prop in source) {
                    if (obj[prop] === undefined) obj[prop] = source[prop];
                }
            }
        });

        return obj;
    }

    sessionStorageAvailable():boolean | undefined {
        if (this.hasSessionStorageSupport !== null) return this.hasSessionStorageSupport;
    
        try {
            this.hasSessionStorageSupport = window !== undefined && window.sessionStorage !== null;
            let testKey = 'i18next.translate.boo';
            window.sessionStorage.setItem(testKey, 'foo');
            window.sessionStorage.removeItem(testKey);
        } 
        catch (e) {
            this.hasSessionStorageSupport = false;
        }
    
        return this.hasSessionStorageSupport;
    };

    localStorageAvailable():boolean | undefined  {
        if (this.hasLocalStorageSupport !== null) return this.hasLocalStorageSupport;
    
        try {
            this.hasLocalStorageSupport = window !== undefined && window.localStorage !== null;
            let testKey = 'i18next.translate.boo';
            window.localStorage.setItem(testKey, 'foo');
            window.localStorage.removeItem(testKey);
        } 
        catch (e) {
            this.hasLocalStorageSupport = false;
        }
    
        return this.hasLocalStorageSupport;
    };

    cacheUserLanguage(lng: string):void {
        let caches: string[] | null = this.options.caches;

        if (!caches) caches = this.options.caches;

        if (!caches) return;

        if (this.options.excludeCacheFor && this.options.excludeCacheFor.indexOf(lng) > -1) return;

        caches.forEach((cacheName: string) => {
            if (this.detectors[cacheName] 
                && this.detectors[cacheName].cacheUserLanguage !== undefined
                && this.detectors[cacheName].cacheUserLanguage instanceof Function) {
                    this.detectors[cacheName].cacheUserLanguage!(lng, this.options);
            }
        });
    };

    cookieMngr = {
        create: function create(this: LanguageDetector, name: string, value:any, minutes:any, domain?: any, cookiesOptions?:any) {
            let cookieOptions = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {
                path: '/',
                sameSite: 'strict'
            };
      
            if (minutes) {
                cookieOptions.expires = new Date();
                cookieOptions.expires.setTime(cookieOptions.expires.getTime() + minutes * 60 * 1000);
            }
        
            if (domain) cookieOptions.domain = domain;
            document.cookie = this.serializeCookie(name, encodeURIComponent(value), cookieOptions);
        }.bind(this),
        read: function read(name: string) {
            let nameEQ = name + '=';
            let ca = document.cookie.split(';');
        
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
        
                while (c.charAt(0) === ' ') {
                    c = c.substring(1, c.length);
                }
        
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
        
            return null;
        },
        remove: function remove(name:string) {
          this.create(name, '', -1);
        }
    };

    serializeCookie(name:string, val:any, options:any):string {
        let opt = options || {};
        opt.path = opt.path || '/';
        let value = encodeURIComponent(val);
        let str = name + '=' + value;
        let fieldContentRegExp = /^[\u0009\u0020-\u007e\u0080-\u00ff]+$/;

        if (opt.maxAge > 0) {
            let maxAge = opt.maxAge - 0;
            if (isNaN(maxAge)) throw new Error('Cookie maxAge should be a Number');
            str += '; Max-Age=' + Math.floor(maxAge);
        }
      
        if (opt.domain) {
            if (!fieldContentRegExp.test(opt.domain)) {
                throw new TypeError('Cookie option domain is invalid');
            }
        
            str += '; Domain=' + opt.domain;
        }
      
        if (opt.path) {
            if (!fieldContentRegExp.test(opt.path)) {
                throw new TypeError('Cookie option path is invalid');
            }
        
            str += '; Path=' + opt.path;
        }
      
        if (opt.expires) {
            if (typeof opt.expires.toUTCString !== 'function') {
                throw new TypeError('Cookie option expires is invalid');
            }
        
            str += '; Expires=' + opt.expires.toUTCString();
        }
      
        if (opt.httpOnly) str += '; HttpOnly';
        if (opt.secure) str += '; Secure';
      
        if (opt.sameSite) {
            let sameSite = typeof opt.sameSite === 'string' ? opt.sameSite.toLowerCase() : opt.sameSite;
        
            switch (sameSite) {
                case true:
                str += '; SameSite=Strict';
                break;
        
                case 'lax':
                str += '; SameSite=Lax';
                break;
        
                case 'strict':
                str += '; SameSite=Strict';
                break;
        
                case 'none':
                str += '; SameSite=None';
                break;
        
                default:
                throw new TypeError('Cookie option sameSite is invalid');
            }
        }
      
        return str;
    };

    //region Detectors 
    navigator: Detector = {
        name: 'navigator',
        lookup: (options: any) => {

            let found: string[] = [];
        
            if (typeof navigator !== 'undefined') {
                if (options.debug) console.info(`Browser config: `, navigator);
                
                // DONORAPP Chrome fix - only 1 language should be returned
                if (navigator.language) {
                    found.push(navigator.language);
                    if (options.debug) console.info(`Browser main language detected: ${found[0]},`);
                    return found.length > 0 ? found : undefined;
                }
        
                if (navigator.languages) {
                    // chrome only; not an array, so can't use .push.apply instead of iterating
                    for (let i = 0; i < navigator.languages.length; i++) {
                        found.push(navigator.languages[i]);
                    }
                }
            } 
        
            return found.length > 0 ? found : undefined;
        }
    }

    sessionStorage: Detector = {
        name: 'sessionStorage',
        lookup: (options: any) => {

            let found;
        
            if (options.lookupSessionStorage && this.sessionStorageAvailable()) {
                let lng = window.sessionStorage.getItem(options.lookupSessionStorage);
                if (lng) found = lng;
            }
    
            return found;
        },
        cacheUserLanguage: (lng:any, options:any) => {
            if (options.lookupSessionStorage && this.sessionStorageAvailable()) {
                window.sessionStorage.setItem(options.lookupSessionStorage, lng);
            }
        }
    };
    
    localStorage: Detector = {
        name: 'localStorage',
        lookup: (options: any):string | undefined => {
            let found;
        
            if (options.lookupLocalStorage && this.localStorageAvailable()) {
                let lng = window.localStorage.getItem(options.lookupLocalStorage);
                if (lng) found = lng;
            }
        
            return found;
        },
        cacheUserLanguage: (lng:any, options:any) => {
            if (options.lookupLocalStorage && this.localStorageAvailable()) {
                window.localStorage.setItem(options.lookupLocalStorage, lng);
            }
        }
    };

    device: Detector = {
        name: 'device',
        lookup: async (options: any): Promise<any> => {
            let found;

            found = (await (Device.getLanguageCode()))?.value;

            if (options.debug) console.info(`Device language detected: ${found}`);
        
            return found;
        }
    };

    subdomain: Detector = {
        name: 'subdomain',
        lookup: (options: any):string | undefined => {
            let found;
      
            if (typeof window !== 'undefined') {
                let language = window.location.href.match(/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/gi);
        
                if (language instanceof Array) {
                    if (typeof options.lookupFromSubdomainIndex === 'number') {
                        found = language[options.lookupFromSubdomainIndex].replace('http://', '').replace('https://', '').replace('.', '');
                    } else {
                        found = language[0].replace('http://', '').replace('https://', '').replace('.', '');
                    }
                }
            }
      
          return found;
        }
    };

    path: Detector = {
        name: 'path',
        lookup: (options: any):string | undefined => {
            let found;
        
            if (typeof window !== 'undefined') {
                let language = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
        
                if (language instanceof Array) {
                    if (typeof options.lookupFromPathIndex === 'number') {
                        if (typeof language[options.lookupFromPathIndex] !== 'string') {
                            return undefined;
                        }
            
                        found = language[options.lookupFromPathIndex].replace('/', '');
                    } else {
                        found = language[0].replace('/', '');
                    }
                }
            }
        
            return found;
        }
    };

    htmlTag: Detector = {
        name: 'htmlTag',
        lookup: (options: any):string | undefined => {
            let found;
            let htmlTag = options.htmlTag || (typeof document !== 'undefined' ? document.documentElement : null);
        
            if (htmlTag && typeof htmlTag.getAttribute === 'function') {
                found = htmlTag.getAttribute('lang');
            }
        
            return found;
        }
    };

    querystring: Detector = {
        name: 'querystring',
        lookup: (options: any):string | undefined => {
            let found;
        
            if (typeof window !== 'undefined') {
                let query = window.location.search.substring(1);
                let params = query.split('&');
        
                for (let i = 0; i < params.length; i++) {
                    let pos = params[i].indexOf('=');
            
                    if (pos > 0) {
                        let key = params[i].substring(0, pos);
            
                        if (key === options.lookupQuerystring) {
                            found = params[i].substring(pos + 1);
                        }
                    }
                }
            }
      
            return found;
        }
    }

    cookie: Detector = {
        name: 'cookie',
        lookup: (options: any):string | undefined => {
            let found;
        
            if (options.lookupCookie && typeof document !== 'undefined') {
                let c = this.cookieMngr.read(options.lookupCookie);
                if (c) found = c;
            }
        
            return found;
        },
        cacheUserLanguage: (lng:any, options:any) => {
            if (options.lookupCookie && typeof document !== 'undefined') {
                this.cookieMngr.create(options.lookupCookie, lng, options.cookieMinutes, options.cookieDomain, options.cookieOptions);
            }
        }
    };
    //endregion
}

export default LanguageDetector