import React from 'react';
import { IonLoading, IonIcon } from '@ionic/react';
import { locationSharp } from 'ionicons/icons';
import LocationDetailIcon from '../components/Svg/LocationDetailIcon';
import { withRouter } from 'react-router-dom';
import { checkLoggedUser, setDefaultSubcenterId } from '../services/UserService';
import ValidationMessage from '../components/ValidationMessage';
import UpdateSubcenterResponse from '../models/backendResponses/UpdateSubcenterResponse'
import RestService from '../services/RestService'
import { isSuccess, getFirstNotificationMessage } from '../models/MetaData'
import { getLoggedUser } from '../services/UserService';
import DASelect from '../components/Core/DASelect';
import DACard from '../components/Core/DACard';
import GetSubcenterDetailsResponse, { SubcenterDetail } from '../models/backendResponses/GetSubcenterDetailsResponse';
import { getCms } from '../services/CmsService';
import DAPage from '../components/Core/DAPage';
import DAHeading from '../components/Core/DAHeading';
import DAButton from '../components/Core/DAButton';
import Notify from '../components/Notifications/component';

interface ChangeLocationState {
    loading: boolean;
    selectedSubcenter: SubcenterDetail | null;
    changeLocaationErrorMessage: string | null;
    subcenters: Array<SubcenterDetail> | null;
}

class ChangeLocation extends React.Component<any, ChangeLocationState> {
    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            selectedSubcenter: null,
            changeLocaationErrorMessage: null,
            subcenters: null,
        }

        this.loadSubcenters();
    }

    async loadSubcenters() {
        checkLoggedUser();
        this.setState({ loading: true });

        let subcentersResponse: GetSubcenterDetailsResponse | null = null;

        try {
            subcentersResponse = await RestService.getSubcenterDetails();
        }
        catch (error) {
            console.error(error);
        }

        this.setState({
            loading: false,
            selectedSubcenter: subcentersResponse?.Subcenters.find(s => s.Id === getLoggedUser()?.MobileUser.DefaultSubcenterId!) ?? null,
            subcenters: subcentersResponse?.Subcenters ?? null
        });
    }

    async changeLocation() {
        this.setState({ loading: true });

        let res: UpdateSubcenterResponse | null;
        try {
            res = await RestService.updateSubcenter(this.state.selectedSubcenter?.Id!);
        }
        catch (error) {
            console.error(error);
            res = null;
        }
            
        if (isSuccess(res?.MetaData)) {
            setDefaultSubcenterId(this.state.selectedSubcenter?.Id!);
            this.setState({ loading: false });
            Notify(getCms('change-location.success'), "success");
            this.goBack();
        } else {
            this.setState({ 
                loading: false,
                changeLocaationErrorMessage: getFirstNotificationMessage(res?.MetaData) ?? "Change failed"
            });
        }        
    }

    getFullAddress(sd: SubcenterDetail | null): string {
        if (sd) {
            return `${sd.AddressStreet}, ${sd.AddressCity}, ${sd.AddressPostalCode}`;
        } else {
            return "";
        }
    }

    goBack() {
        this.props.history.push("/Profile");
    }

    render() {
        return (
            <DAPage hideHeaderOnMobile>
                <IonLoading isOpen={this.state.loading} />
                
                <div className="flex ion-align-items-center flex-direction-vertical">
                    <DAHeading onClick={this.goBack.bind(this)} 
                        text={getCms("change-location.title")} 
                        hideHeaderOnMobile />

                    <DACard cardClass="card card-action dots-bg-end-top dots-bg-start-bottom"
                            title={getCms('change-location.title')}
                            icon={<LocationDetailIcon />}
                            subtitle={getCms('change-location.instruction')}
                            showTitleOnMobileOnly>

                        <div className="subcenter-chooser">
                            <div className="ion-padding-top">
                                {this.state.selectedSubcenter && 
                                    <DASelect labelText={getCms('subcenter-chooser.location')}
                                        optionsValue="Name"
                                        optionsKey="Id"
                                        value={this.state.selectedSubcenter}
                                        onChange={(e: any) => { 
                                                this.setState({ selectedSubcenter: e.detail.value });
                                            }
                                        }
                                        options={this.state.subcenters} />
                                }

                                <div className="subcenter-info">
                                    <IonIcon icon={locationSharp} className="ion-margin-end text-color-secondary-main icon-medium"></IonIcon>
                                    
                                    <span className="name">{this.state.selectedSubcenter?.Name}</span>
                                    <div className="address">{this.getFullAddress(this.state.selectedSubcenter)}</div>
                                </div>
                            </div>

                            <ValidationMessage message={this.state.changeLocaationErrorMessage} />

                            <DAButton 
                                className="mt-increased mt-great-m" 
                                color="primary" 
                                expand="block"
                                submitOnEnter
                                size="large"
                                onClick={async () => await this.changeLocation()}>
                                    {getCms('change-location.confirm')}
                            </DAButton>
                        </div>
                    </DACard>
                </div>
            </DAPage>
        );
    }
};

export default withRouter(ChangeLocation);