import React from 'react';

class ProfileIcon extends React.Component {
    render() {
        return (
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 3.83398C3 5.48798 4.346 6.83398 6 6.83398C7.654 6.83398 9 5.48798 9 3.83398C9 2.17998 7.654 0.833984 6 0.833984C4.346 0.833984 3 2.17998 3 3.83398ZM11.3333 13.5007H12V12.834C12 10.2613 9.906 8.16732 7.33333 8.16732H4.66667C2.09333 8.16732 0 10.2613 0 12.834V13.5007H11.3333Z" />
            </svg>
        );
    }
}

export default ProfileIcon;