import React from 'react';
import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';

import ValidationMessage from '../ValidationMessage';
import DAInputLabel from './DAInputLabel';


interface DASelectProps {
    options: Array<any> | null;
    optionsKey?: string;
    optionsValue?: string;
    formateValue?: Function;
    value?: any;
    placeholder?: string;

    onChange?: Function;
    labelText?: string;
    cssClass?: string;

    disabled?: boolean;
    isInvalid?: boolean;
    isFormSubmited?: boolean;
    errorMessage?: any;
}

interface DASelectState {
    value: any
}


class DASelect extends React.Component<DASelectProps, DASelectState> {

    constructor(props: DASelectProps) {
        super(props)

        this.state = { value: props.value }
    }

    componentDidUpdate(prevProps: DASelectProps, prevState: DASelectState) {
        if(prevProps.options !== this.props.options) {
            this.setState({ value: null });
        }
    }

    onChange = (e: any) => {
        this.setState({ value: e.detail.value })
        if(this.props.onChange) {
            this.props.onChange(e)
        }
    }

    isSomeError = () : boolean => {
        return this.props.isInvalid || (this.props.isFormSubmited !== undefined && this.props.isFormSubmited && this.props.errorMessage !== null && this.props.errorMessage !== undefined)
    }

    formatValue = (data: any) : string => {
        if(this.props.formateValue) {
            return this.props.formateValue(data);
        }
        
        return data?.toString().trim() ?? '';
    }

    render() {
        return (
            <React.Fragment>
                <DAInputLabel labelText={this.props.labelText}/>
                <IonItem className={`da-input-wrap ${this.isSomeError() ? "da-input-invalid" : ""} ${this.props.cssClass ?? ""}`.trim()}>
                    <IonSelect
                        placeholder={this.props.placeholder}
                        className="da-select"
                        interface="popover"
                        value={this.state.value}
                        disabled={this.props.disabled}
                        justify="space-between"
                        onIonChange={e => this.onChange(e)}>
                        {
                            this.props.options === null ? "" :
                            this.props.options.map((s, i, a) => <IonSelectOption 
                                key={this.props.optionsKey ? s[this.props.optionsKey] : i} 
                                value={s}>{this.formatValue(this.props.optionsValue ? s[this.props.optionsValue] : s)}
                                </IonSelectOption>)
                        }
                    </IonSelect>
                </IonItem>
                <ValidationMessage message={this.props.isFormSubmited ? this.props.errorMessage : null} />

            </React.Fragment>
        );
    }
};

export default DASelect;