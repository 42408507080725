import RegexHelper from '../helpers/RegexHelper';
import { getCms } from './CmsService';

export class ValidatableInput {
    value: string;
    required: boolean;
    requiredMessage?: string;
    customValidation?: (value: string) => string | null;

    constructor(
        required: boolean = false, 
        requiredMessage?: string, 
        value: string = '', 
        customValidation?: (value: string) => string | null) {

        this.required = required;
        this.requiredMessage = requiredMessage;
        this.value = value;
        this.customValidation = customValidation;
    }

    setValue(value: string): ValidatableInput {
        this.value = value;
        return this;
    }

    getErrorMessage(): string | null {
        if (this.required) {
            if (this.value == null || this.value.length === 0) {
                return this.requiredMessage ?? null;
            }
        }

        if (this.customValidation) {
            return this.customValidation(this.value);
        }

        return null;
    }

    isValid(): boolean {
        let errorMessage = this.getErrorMessage();
        return errorMessage === null;
    }
}

export class ValidatableCheckbox {
    checked: boolean;
    required: boolean;
    requiredMessage?: string;

    constructor(required: boolean = false, requiredMessage?: string, checked: boolean = false) {
        this.required = required;
        this.requiredMessage = requiredMessage;
        this.checked = checked;
    }

    setValue(checked: boolean): ValidatableCheckbox {
        this.checked = checked;
        return this;
    }

    getErrorMessage(): string | null {
        if (this.required) {
            if (!this.checked) {
                return this.requiredMessage ?? null;
            }
        }

        return null;
    }

    isValid(): boolean {
        let errorMessage = this.getErrorMessage();
        return errorMessage === null;
    }
}

export class ValidatableDate {
    date: Date | null;
    required: boolean;
    requiredMessage?: string;

    constructor(required: boolean = false, requiredMessage?: string, date: Date | null = null) {
        this.required = required;
        this.requiredMessage = requiredMessage;
        this.date = null;
    }

    setValue(date: Date | null): ValidatableDate {
        this.date = date;
        return this;
    }

    getErrorMessage(): string | null {
        if (this.required) {
            if (!this.date) {
                return this.requiredMessage ?? null;
            }
        }

        return null;
    }

    isValid(): boolean {
        let errorMessage = this.getErrorMessage();
        return errorMessage === null;
    }
}

export class ValidatableNumber {
    number: number | null;
    required: boolean;
    requiredMessage?: string;

    constructor(required: boolean = false, requiredMessage?: string, number: number | null = null) {
        this.required = required;
        this.requiredMessage = requiredMessage;
        this.number = null;
    }

    setValue(number: number | null): ValidatableNumber {
        this.number = number;
        return this;
    }

    getErrorMessage(): string | null {
        if (this.required) {
            if (!this.number) {
                return this.requiredMessage ?? null;
            }
        }

        return null;
    }

    isValid(): boolean {
        let errorMessage = this.getErrorMessage();
        return errorMessage === null;
    }
}

export function getErrorMessageEmail(value: string, phone: string): string | null {
    if (value) {
        let isEmail = RegexHelper.isValidEmail(value);

        if (!isEmail) {
            return getCms('registration.validationMessages.incorrectEmailFormat');
        }
    } else if (!phone) {
        return getCms('registration.validationMessages.enterUsername');
    }

    return null;
}

export function getErrorMessagePhone(value: string, email: string): string | null {
    if (value) {
        if (value.length > 15) {
            return getCms('registration.validationMessages.phoneTooLong');
        }

        let isPhone = RegexHelper.isValidPhone(value);

        if (!isPhone) {
            return getCms('registration.validationMessages.incorrectPhoneFormat');
        }
    } else if (!email) {
        return getCms('registration.validationMessages.enterUsername');
    }

    return null;
}

export function getErrorMessagePassword(value: string, password2: string): string | null {
    if (value) {
        if (value.length < 8) {
            return getCms('registration.validationMessages.weekPasswordLength');                
        }

        if (!value.match(/[A-Z]+/)){
            return getCms('registration.validationMessages.weekPasswordCapital');
        }            

        if (!value.match(/[0-9]+/)){
            return getCms('registration.validationMessages.weekPasswordNumber');
        }
        
        if (value !== password2) {
            return getCms('registration.validationMessages.passwordsNotMatch');                
        }
    }

    return null;
}

export function getErrorMessageUsername(email: string, phone: string): string | null {
    if (!email && !phone) {
        return getCms('registration.validationMessages.enterUsername');
    }

    return null;
}

export function getErrorMessageDialingCode(dialingCode: string, phoneNumber: string): string | null {
    if(phoneNumber && phoneNumber.length > 0 && (dialingCode === null || dialingCode.length < 1)) {
        return getCms('registration.validationMessages.diallingCode');
    }
    return null
}