import React from 'react';

class DashboardIcon extends React.Component<any> {
    render() {
        return (
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M8.8 4.54102H7.2V7.74102H4V9.34102H7.2V12.541H8.8V9.34102H12V7.74102H8.8V4.54102ZM8 0.541016C3.584 0.541016 0 4.12502 0 8.54102C0 12.957 3.584 16.541 8 16.541C12.416 16.541 16 12.957 16 8.54102C16 4.12502 12.416 0.541016 8 0.541016ZM8 14.941C4.472 14.941 1.6 12.069 1.6 8.54102C1.6 5.01302 4.472 2.14102 8 2.14102C11.528 2.14102 14.4 5.01302 14.4 8.54102C14.4 12.069 11.528 14.941 8 14.941Z" fill="white" />
            </svg>
        );
    }
}

export default DashboardIcon;