import React from 'react';
import { IonLoading, IonButton, withIonLifeCycle } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { ValidatableInput } from "../services/Validation";
import KyesIcon from '../components/Svg/KeysIcon';
import DAInput from '../components/Core/DAInput';
import DACard from '../components/Core/DACard';
import ValidationMessage from '../components/ValidationMessage';
import ResetPasswordResponse from '../models/backendResponses/ResetPasswordResponse'
import RestService from '../services/RestService'
import { isSuccess, getFirstNotificationMessage } from '../models/MetaData'
import { getCms } from '../services/CmsService';
import DAPage from '../components/Core/DAPage';
import DAHeading from '../components/Core/DAHeading';
import Notify from '../components/Notifications/component';

interface LostPasswordState {
    loading: boolean;
    formSubmitted: boolean;
    email: ValidatableInput;
    recoverErrorMessage: string | null;
}

class LostPassword extends React.Component<any, LostPasswordState> {
    constructor(props: any) {
        super(props);

        this.state = this.getInitState();
    }

    ionViewWillEnter() {
        this.setState(this.getInitState());
    }

    getInitState() {
        return {
            loading: false,
            formSubmitted: false,
            email: new ValidatableInput(true, getCms('lost-password.validationMessages.enterUsername')),
            recoverErrorMessage: null
        };
    }

    async recover() {
        this.setState({ formSubmitted: true });

        if (!this.isValid()) {
            return;
        }

        this.setState({ loading: true });

        let res: ResetPasswordResponse | null;
        try {
            res = await RestService.resetPassword(this.state.email.value);
        }
        catch (error) {
            console.error(error);
            res = null;
        }
            
        if (isSuccess(res?.MetaData)) {
            this.setState({ 
                email: this.state.email.setValue(''),
                loading: false
            });

            Notify(getCms('lost-password.success'), "success");
            this.props.history.push("/");
        } else {
            this.setState({ 
                loading: false,
                recoverErrorMessage: getFirstNotificationMessage(res?.MetaData) ?? "Recover failed"
            });
        }        
    }

    isValid(): boolean {
        return this.state.email.isValid();
    }

    goBack() {
        this.props.history.push("/");
    }

    render() {
        return (
            <DAPage hideHeaderOnMobile>
                <IonLoading isOpen={this.state.loading} />

                <div className="flex ion-align-items-center flex-direction-vertical">
                    <DAHeading onClick={this.goBack.bind(this)} 
                        text={getCms("lost-password.title")} 
                        hideHeaderOnMobile />

                    <DACard cardClass="card card-action dots-bg-end-top dots-bg-start-bottom"
                            title={getCms('lost-password.title')}
                            icon={<KyesIcon />}
                            subtitle={getCms('lost-password.instruction')}
                            showTitleOnMobileOnly>

                        <div className="flex ion-align-items-center flex-direction-vertical">
                            <div className="mb-increased mb-great-m">
                                <DAInput labelText={getCms('lost-password.username')}
                                    type="email"
                                    value={this.state.email.value}
                                    onChange={(e: any) => this.setState({ email: this.state.email.setValue(e.detail.value!) })}
                                    isFormSubmited={this.state.formSubmitted}
                                    placeholder={getCms('lost-password.emailPlaceholder')}
                                    errorMessage={this.state.email.getErrorMessage()} />

                                <ValidationMessage message={this.state.recoverErrorMessage} />
                            </div>

                            <IonButton 
                                color="danger" 
                                expand="block"
                                size="large"
                                onClick={async () => await this.recover()}>
                                    {getCms('lost-password.reset')}
                            </IonButton>
                        </div>
                    </DACard>
                </div>
            </DAPage>
        );
    }
};

export default withRouter(withIonLifeCycle(LostPassword));