import { Capacitor } from '@capacitor/core';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import RestService from './RestService';
import { getLoggedUser } from './UserService';

export default class FCMService {
  static init() {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
        FCMAndroid.initNotifications();
    } else {
      console.error('Push notifications not available');
    }
  }
}

export class FCMAndroid {
  
  static initNotifications() {
    PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
                console.error('Push Notification permission denied');
            }
            else {
              this.register();
            }
          });
        } else {
          this.register();
        }
      });
  }

  static register() {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token: Token) => {
            console.info('Push registration success', token);
            let user = getLoggedUser();
            if (user) {
              RestService.setNotificationToken(user.MobileUser?.Id, token);
            }
        }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error: any) => {
            alert('Error on registration: ' + JSON.stringify(error));
        }
    );

    /* // Show us the notification payload if the app is open on our device - not implemented yet
    PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
            this.setState({
                showNotification: true,
                notificationMsg: `${notification.title} ${notification.body}`
            });
        }
    );

    // Method called when tapping on a notification - not implemented yet
    PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
            this.setState({
                showNotification: true,
                notifications: [ notification ]
            });
        }
    ); */
  }
}

// Not used yet
export class FirebaseWeb {
    vapidKey: string;
    config: object;
    app: any;
    analytics: any;
    messaging: any;

    static _instance: FirebaseWeb;

    static instance = (): FirebaseWeb => {
        if(!FirebaseWeb._instance) {
            FirebaseWeb._instance = new FirebaseWeb();
        }
        return FirebaseWeb._instance;
    }

    constructor() {
        this.vapidKey= 'BO1265JnFIZ-nrVgO5ImZLg6mt44iC4NiBIh8N8wR1qcbDhu_NViQdIOGOuCVDbAcbuie_cV-3RReX968LSYS6c';

        this.config = {
            /* apiKey: "AIzaSyDUr_z0TJCZSMugQDuTNjviRvwczkUjj-Y",
            authDomain: "plasmastream-2e47a.firebaseapp.com",
            projectId: "plasmastream-2e47a",
            storageBucket: "plasmastream-2e47a.appspot.com",
            messagingSenderId: "887039543335",
            appId: "1:887039543335:web:c1c5a3673a8f69937f6602",
            measurementId: "G-C6ZWMCT89H" */
        };

        this.init();
    }

    init() {
        this.app = initializeApp(this.config);
        this.analytics = getAnalytics(this.app);
        this.messaging = getMessaging();
        // Add the public key generated from the console here.
        /* this.messaging.getToken({vapidKey: this.vapidKey});
        getToken(this.messaging, {vapidKey: this.vapidKey}); */

        getToken(this.messaging, { vapidKey: this.vapidKey }).then((currentToken: any) => {
            if (currentToken) {
                console.log('currentToken', currentToken);
              // Send the token to your server and update the UI if necessary
              // ...
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err: any) => {
            console.log('An error occurred while retrieving token. ', err);
          });

          onMessage(this.messaging, (payload) => {
            console.log('Message received. ', payload);
          });
    }
}