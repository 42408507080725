import React from "react";
import { IonButton, withIonLifeCycle } from "@ionic/react";
import { ValidatableInput } from "../services/Validation";
import { getCms, getCmsAssetUrl } from "../services/CmsService";
import { BaseState } from "../models/BaseState";
import DAInput from "./Core/DAInput";
import DAButton from "./Core/DAButton";
import { Link } from "react-router-dom";
import ValidationMessage from "./ValidationMessage";
import { getSavedCredential } from "../services/UserService";

interface LoginFormProps {
    onSubmit: Function
    onRegister: Function
    loginErrorMessage: string | null
}

interface LoginFormState extends BaseState {
    formSubmitted: boolean
    email: ValidatableInput
    password: ValidatableInput
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
    constructor(props: LoginFormProps) {
        super(props);

        this.state = {
            formSubmitted: false,
            email: new ValidatableInput(true, getCms('login.validationMessages.enterUsername')),
            password: new ValidatableInput(true, getCms('login.validationMessages.enterPassword'))
        };
    }

    async ionViewWillEnter() {
        let loginCredential = await getSavedCredential();

        this.setState({
            formSubmitted: false,
            email: this.state.email.setValue(loginCredential?.login ?? ''),
            password: this.state.password.setValue(loginCredential?.password ?? '')
        });
    }

    isValid(): boolean {
        return this.state.email.isValid() && this.state.password.isValid();
    }

    async submitBtnClick() {
        this.setState({ formSubmitted: true });

        if (!this.isValid()) {
            return;
        }

        try {
           await this.props.onSubmit(this.state.email.value, this.state.password.value);
        }
        catch (error) {
            console.error('ERROR LoginForm.submitBtnClick', error);
        }
    }

    render():React.ReactNode {
        return (
            <div className="login-form card ion-text-left dots-bg-login-end-bottom dots-bg-login-start-top">
                <div style={{backgroundImage: `url('${getCmsAssetUrl('login-form-image-left-top', '/assets/img/login-img-1-frame.png')}')`}} className='login-form--image-left-top'></div>
                
                <div className="login-form--container">
                    <h2 className="ion-text-center font-size-heading-2 font-weight-bold mt-none mb-small">{getCms('login.slogan')} <span className='text-color-success'>{getCms('login.sloganSuffix')}</span></h2>

                    <IonButton fill="outline" color="danger" expand="block" 
                        onClick={(e:any) => this.props.onRegister(e)}>
                            {getCms('login.registerButton')}
                    </IonButton>

                    <div className="ion-text-center line-through">{getCms('login.or')}</div>

                    <div className='flex flex-direction-vertical gap-regular'>  
                        <DAInput labelText={getCms('login.username')}
                            type="email"
                            autocomplete={true}
                            value={this.state.email.value} 
                            onChange={(e: any) => this.setState({ email: this.state.email.setValue(e.detail.value!)})}
                            isFormSubmited={this.state.formSubmitted}
                            errorMessage={this.state.email.getErrorMessage()} />

                        <DAInput labelText={getCms('login.password')} 
                            type="password"
                            autocomplete={true}
                            value={this.state.password.value} 
                            onChange={(e: any) => this.setState({ password: this.state.password.setValue(e.detail.value!) })}
                            isFormSubmited={this.state.formSubmitted}
                            errorMessage={this.state.password.getErrorMessage()} />
                    </div>

                    <ValidationMessage message={this.props.loginErrorMessage} />

                    <DAButton color="primary" 
                        expand="block"
                        submitOnEnter
                        text={getCms('login.login')}
                        onClick={async () => await this.submitBtnClick()} />
                    
                    <Link className="ion-text-center" to="/LostPassword">{getCms('login.lostPassword')}</Link>
                </div>
                
                <div style={{backgroundImage: `url('${getCmsAssetUrl('login-form-image-right-bottom', '/assets/img/login-img-2.png')}')`}} className='login-form--image-right-bottom'></div>
            </div>
        )
    }
}

export default withIonLifeCycle(LoginForm);