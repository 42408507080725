import React from 'react';
import {
    withIonLifeCycle,
    IonButton,
    IonList,
    IonItem,
    IonListHeader,
    IonLabel,
    IonCardHeader,
    IonCardTitle,
    IonImg,
    IonSegmentButton,
    IonSegment } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import DACard from '../Core/DACard';
import { logoutUser } from '../../services/UserService';
import GetDonorProfileResponse from '../../models/backendResponses/GetDonorProfileResponse';
import EnvelopeIcon from '../../components/Svg/EnvelopeIcon';
import LocationIcon from '../../components/Svg/LocationIcon';
import ProfileIcon from '../../components/Svg/ProfileIcon';
import PhoneIcon from '../../components/Svg/PhoneIcon';
import { changeLanguage } from '../../services/I18n';
import photoplaceholer from '../../theme/img/person-placeholder.jpg';
import { getAppLanguages, getCms } from '../../services/CmsService';
import DAPopup from '../Core/DAPopup';
import RestService from '../../services/RestService';
import CheckmarkIcon from '../Svg/CheckmarkIcon';

interface ProfileCardProps {
    donorProfile: GetDonorProfileResponse | null;
    onChangeLocation: Function;
    onChangePsw: Function;
}

interface ProfileCardState {
    popoverVisible: boolean;
    langPopoverVisible: boolean;
    popoverEvent: Event | undefined;
    alertDeleteAccount: boolean;
}

class ProfileCard extends React.Component<ProfileCardProps, ProfileCardState> {
    constructor(props: ProfileCardProps) {
        super(props);

        this.state = {
            popoverVisible: false,
            langPopoverVisible: false,
            popoverEvent: undefined,
            alertDeleteAccount: false
        };
    }

    getDonorAddress(): string | null {
        if (!this.props.donorProfile) {
            return null;
        }

        if (this.props.donorProfile.AddressPermanentStreet && this.props.donorProfile.AddressPermanentCity) {
            return `${this.props.donorProfile.AddressPermanentStreet}, ${this.props.donorProfile.AddressPermanentCity}`;
        } else if (this.props.donorProfile.AddressPermanentStreet) {
            return this.props.donorProfile.AddressPermanentStreet;
        } else {
            return this.props.donorProfile.AddressPermanentCity;
        }
    }

    getPhotoUrl():string {
        let env = this.getEnvUrl();
        return env.length && this.props.donorProfile?.PhotoUrl ?
            `${env}${this.props.donorProfile?.PhotoUrl}` : photoplaceholer;
    }

    getEnvUrl():string {
        let env = process.env.react_app_api_url;

        if (!env || !env?.length) return '';
        // The '/' at the end should be removed, there is a '/' at the beginning of PhotoUrl
        if (env[env.length - 1] === '/') env = env.slice(0, env.length - 1);

        return env;
    }

    deleteAccount = async () => {
        await RestService.deleteUser();
        await logoutUser();
    }

    renderLanguageTabs = () => {
        return getAppLanguages().map((lang, i, arr) => {
            let isSelected = i18next.language === lang.IsoCode;

            return (
                <React.Fragment key={`languageSegment-${i}`}>
                    <IonSegmentButton layout='icon-start' key={`languageBtn-${i}`} value={lang.IsoCode}>
                        <IonLabel className='ion-no-margin'>
                            {isSelected && <CheckmarkIcon />}
                            {getCms(`languages.${lang.IsoCode}`)}
                        </IonLabel>
                    </IonSegmentButton>
                </React.Fragment>
            )
        });
    }

    renderPhoneNumber() {
        if (!this.props.donorProfile) {
            return null;
        }

        return (
            <>
                <PhoneIcon />
                {`${this.props.donorProfile.CountryCallingCode} ${this.props.donorProfile.Phone}`}
            </>
        );
    }

    languageSwitch = (e: any) => {
        changeLanguage(e.detail.value);
    }

    render() {
        return (
            <DACard cardClass="card card-full-width ion-no-margin dots-bg-start-bottom dots-bg-end-top">
                <IonCardHeader className='ion-hide-md-up mb-default'>
                    <IonCardTitle>{getCms("donor-profile.title")}</IonCardTitle>
                </IonCardHeader>

                <div className='flex flex-direction-vertical gap-big'>
                    <div className='flex flex-direction-vertical flex-row-m gap-increased gap-big-m'>
                        <div className='flex ion-justify-content-center'>
                            <IonImg className='donor-photo' src={this.getPhotoUrl()} />
                        </div>

                        <div className='donor-info'>
                            <IonList className='donor-profile'>
                                <IonListHeader className='mb-little'>
                                    <IonLabel>{this.props.donorProfile?.FirstName} {this.props.donorProfile?.LastName}</IonLabel>
                                </IonListHeader>

                                <IonItem>
                                    <IonLabel>
                                        <ProfileIcon /> <div className='ion-text-wrap'>{getCms("donor-profile.username")}: {this.props.donorProfile?.Username}</div>
                                    </IonLabel>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <EnvelopeIcon /> {this.props.donorProfile?.Email}
                                    </IonLabel>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <LocationIcon /> {this.getDonorAddress()}
                                    </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        {this.renderPhoneNumber()}
                                    </IonLabel>
                                </IonItem>
                            </IonList>
                        </div>
                    </div>

                    <div className='flex flex-direction-vertical flex-row-m gap-regular gap-increased-md'>
                        <IonButton color="primary" fill="outline" expand="block" onClick={(e: any) => this.props.onChangeLocation(e)}>
                            {getCms("donor-profile.changeLocation")}
                        </IonButton>

                        <IonButton color="primary" fill="outline" expand="block" onClick={() => this.props.onChangePsw()}>
                            {getCms("donor-profile.changePassword")}
                        </IonButton>

                        <IonButton color="primary" fill="outline" expand="block" onClick={() => this.setState({ alertDeleteAccount: true })}>
                            {getCms("donor-profile.deleteAccount")}
                        </IonButton>
                    </div>

                    <div className='flex flex-direction-vertical gap-regular'>
                        <div className='font-weight-bold text-color-blue-dark'>
                            {getCms('donor-profile.changeLanguage')}:
                        </div>

                        <div>
                            <IonSegment className='da-segment da-segment-plain'
                                swipeGesture={false}
                                value={i18next.language}
                                onIonChange={this.languageSwitch}>

                                {this.renderLanguageTabs()}
                            </IonSegment>
                        </div>
                    </div>

                    <div className='ion-hide-md-up'>
                        <IonButton
                            fill="outline"
                            color="danger"
                            expand="block"
                            onClick={() => logoutUser()}>
                            {getCms('common.logout')}
                        </IonButton>
                    </div>
                </div>

                <DAPopup open={this.state.alertDeleteAccount}
                    onDismiss={() => this.setState({ alertDeleteAccount: false })}
                    header={getCms('donor-profile.deleteAccountConfirmationHeader')}
                    message={getCms('donor-profile.deleteAccountConfirmation')}
                    buttons={[
                        {
                            text: getCms('common.back'),
                            role: 'cancel'
                        },
                        {
                            text: getCms('common.confirm'),
                            handler: async () => {
                                await this.deleteAccount();
                            },
                            cssClass: 'primary'
                        }
                    ]} />
            </DACard>
        );
    }
};

export default withRouter(withIonLifeCycle(ProfileCard));