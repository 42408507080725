import { toast, ToastContainer } from 'react-toastify';
import { TypeOptions } from 'react-toastify/dist/types';
import 'react-toastify/dist/ReactToastify.css';

import { FunctionComponent, PropsWithChildren } from 'react';
import CheckmarkIcon from '../Svg/CheckmarkIcon';

export interface NotificationsProps {
    toastAutocloseDelay?: number
}

export const Notifications:FunctionComponent<NotificationsProps> = ({toastAutocloseDelay}: PropsWithChildren<NotificationsProps>) => {
    return (
        <ToastContainer position='bottom-center'
            autoClose={toastAutocloseDelay ?? 15000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnHover
            closeButton={true}
            draggable={false} />
    )
}

const Notify = (message: string, messageType: TypeOptions = 'error') => {
    switch(messageType) {
		case toast.TYPE.SUCCESS:
			return toast(message, {
                type: messageType,
                icon: <CheckmarkIcon />
			});
		default:
			return toast(message, {
			    type: messageType
			});
    }
}

export default Notify;