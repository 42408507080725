import React from 'react';
import { IonLoading, IonCheckbox, IonCol, IonItem, withIonLifeCycle, IonRow, isPlatform } from '@ionic/react';
import ValidationMessage from '../components/ValidationMessage';
import GdprLink from '../components/GdprLink';
import { RouteComponentProps, withRouter } from "react-router-dom";
import qs from "qs";
import { ValidatableInput, ValidatableCheckbox, getErrorMessageEmail, getErrorMessagePassword } from "../services/Validation";
import RestService from '../services/RestService';
import GTMService from '../services/GTMService';
import DonorType from '../enums/DonorType';
import { getFirstNotificationMessage, isSuccess } from '../models/MetaData';
import { setLoggedUser } from '../services/UserService';
import RegisterExistingUserResponse from '../models/backendResponses/RegisterExistingUserResponse'
import { SubcenterDetail } from '../models/backendResponses/GetSubcenterDetailsResponse'
import DAInput from './Core/DAInput';
import SubcenterChooser from '../components/SubcenterChooser';
import { getCms } from '../services/CmsService';
import { LoginCredential } from '../models/LoginCredential';
import DAButton from './Core/DAButton';

interface RegisterExistingState {
    loading: boolean;
    formSubmitted: boolean;
    email: ValidatableInput;
    password: ValidatableInput;
    password2: ValidatableInput;
    donorCode: ValidatableInput;
    gdprAgreement: ValidatableCheckbox;
    selectedSubcenter: SubcenterDetail | null;
    registrationErrorMessage: string | null;
}

interface RegisterExistingProps extends RouteComponentProps {
    history: any;
    subcenters: Array<SubcenterDetail> | null;
    selectedSubcenter: SubcenterDetail | null;  
}

class RegisterExisting extends React.Component<RegisterExistingProps, RegisterExistingState> {
    constructor(props: RegisterExistingProps) {
        super(props);

        let center = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).center as string;
        
        this.state = {
            loading: false,
            formSubmitted: false,
            email: new ValidatableInput(true, getCms('registration.validationMessages.enterUsername'), '', (value) => getErrorMessageEmail(value, '')),
            password: new ValidatableInput(true, getCms('registration.validationMessages.enterPassword'), '', (value) => getErrorMessagePassword(value, this.state.password2.value)),
            password2: new ValidatableInput(true, getCms('registration.validationMessages.enterPassword')),
            donorCode: new ValidatableInput(true, getCms('registration.validationMessages.enterDonorCode')),
            gdprAgreement: new ValidatableCheckbox(true, getCms('registration.validationMessages.gdprAgreementRequired')),
            selectedSubcenter: this.props.subcenters?.find(s => s.Id === parseInt(center)) ?? null,
            registrationErrorMessage: null
        };
    }

    async registerExistingDonor() {
        this.setState({ formSubmitted: true });

        if (!this.isValid()) {
            return;
        }

        this.setState({ loading: true });

        let res: RegisterExistingUserResponse | null;

        try {
            res = await RestService.registerExistingUser(
                this.state.email.value, 
                this.state.password.value,
                this.state.donorCode.value,
                this.state.selectedSubcenter!.Id);
        }
        catch (error) {
            console.error(error);
            res = null;
        }

        if (isSuccess(res?.MetaData)) {
            GTMService.registrationEvent(this.state.selectedSubcenter!.Name ?? null, DonorType.AlreadyDonor);
            let thankYouUrl = await RestService.getSetting('DonorApp.ThankYouUrl.Registration', null);
            if (thankYouUrl.SettingValue?.length) window.open(thankYouUrl.SettingValue, '_blank');

            var resLogin = await RestService.login(
                this.state.email.value, 
                this.state.password.value);

            if (resLogin?.Token) {
                await setLoggedUser(resLogin, new LoginCredential(this.state.email.value, this.state.password.value));
                this.setState({ loading: false });
                window.location.replace("/Dashboard");
            } else {
                this.setState({ 
                    loading: false,
                    registrationErrorMessage: getFirstNotificationMessage(res?.MetaData) ?? "Login failed"
                });
            }
        } else {
            this.setState({ 
                loading: false,
                registrationErrorMessage: getFirstNotificationMessage(res?.MetaData) ?? "Registration failed"
            });
        }
    }

    handleInputFocus = (e: any) => {

        if (isPlatform('ios')) {
          setTimeout(() => {
            e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 100);
        }
      }

    isValid(): boolean {
        return this.state.email.isValid() 
            && this.state.password.isValid() 
            && this.state.password2.isValid() 
            && this.state.donorCode.isValid() 
            && this.state.gdprAgreement.isValid()
            && this.state.selectedSubcenter !== null;
    }

    render() {
        return (
            <React.Fragment>
                <IonLoading isOpen={this.state.loading} />

                <div className="register-existing">
                    <div className="flex flex-direction-vertical">
                        <div className="flex flex-direction-vertical">
                            {/* <IonCol sizeXs="12" className="ion-margin-top ion-no-padding">
                                <IonText>{getCms('registration.slogan')}</IonText>
                            </IonCol> */}

                            <h2 className="ion-text-left ion-no-margin text-color-black mb-regular">{getCms('registration.formTitlePersonal')}</h2>
                        </div>
                        
                        <div className='page-registration__form mb-great'>
                            <DAInput type="email"
                                labelText={getCms('registration.username')}
                                value={this.state.email.value} 
                                onChange={(e:any) => this.setState({ email: this.state.email.setValue(e.detail.value!)}) }
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.email.getErrorMessage()}
                                onFocus={this.handleInputFocus}
                            />
                                
                            <DAInput type="text"
                                labelText={getCms('registration.donorCode')}
                                value={this.state.donorCode.value}
                                onChange={(e:any) => this.setState({ donorCode: this.state.donorCode.setValue(e.detail.value!)}) } 
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.donorCode.getErrorMessage()}
                                onFocus={this.handleInputFocus}
                            />
                        </div>
                                
                        <div>
                            <h2 className="ion-text-left text-color-black mb-regular">{getCms('registration.formTitlePassword')}</h2>
                        </div>
                        
                        <div className='page-registration__form mb-great'>
                            <DAInput type="password"
                                labelText={getCms('registration.password')}
                                value={this.state.password.value}
                                onChange={(e:any) => this.setState({ password: this.state.password.setValue(e.detail.value!) }) }
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.password.getErrorMessage()}
                                tooltipContent={[
                                    getCms('registration.validationMessages.weekPasswordLength'), 
                                    getCms('registration.validationMessages.weekPasswordCapital'),
                                    getCms('registration.validationMessages.weekPasswordNumber')
                                ]}
                                onFocus={this.handleInputFocus}
                            />
                                
                            <DAInput type="password"
                                labelText={getCms('registration.repeatPassword')}
                                value={this.state.password2.value}
                                onChange={(e:any) => this.setState({ password2: this.state.password2.setValue(e.detail.value!) })}
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.password2.getErrorMessage()}
                                onFocus={this.handleInputFocus}
                            />
                        </div>
                        
                        <div className="mb-big mb-great-xl">
                            <SubcenterChooser 
                                formSubmitted={this.state.formSubmitted}
                                subcenters={this.props.subcenters}
                                selectedCenter={this.state.selectedSubcenter}
                                subcenterSelected={(value: SubcenterDetail) => { this.setState({ selectedSubcenter: value }); } } 
                            />
                        </div>

                        <div className='flex ion-align-items-center flex-direction-vertical mb-big gap-medium'>
                            <IonItem className="full-height ion-no-padding">
                                <IonCheckbox 
                                    className="ion-no-margin mr-medium"
                                    color="primary" 
                                    slot="start"
                                    onIonChange={e => this.setState({ gdprAgreement: this.state.gdprAgreement.setValue(e.detail.checked) })}>
                                </IonCheckbox>
                                
                                <GdprLink subcenter={this.state.selectedSubcenter} />
                            </IonItem>

                            <ValidationMessage message={this.state.formSubmitted ? this.state.gdprAgreement.getErrorMessage() : null} />
                        </div>
                        
                        <div>
                            <ValidationMessage message={this.state.registrationErrorMessage} />
                        </div>

                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6" sizeLg="4" sizeXl="3">
                                <DAButton color="danger" 
                                    expand="full"
                                    submitOnEnter
                                    text={getCms('registration.register')}
                                    onClick={async () => await this.registerExistingDonor()} />
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

export default withRouter(withIonLifeCycle(RegisterExisting));
