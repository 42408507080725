import React from 'react';
import DAInput from './DAInput';
import DASelect from './DASelect';
import DAInputLabel from './DAInputLabel';
import ValidationMessage from '../ValidationMessage';
import { CountryCallingCode } from '../../models/backendResponses/GetCallingCodesResponse';

interface DAPhoneInputProps {
    phoneValue?: any;
    dialingCodeValue?: any;
    diallingCodes: Array<CountryCallingCode> | null;
    onChange?: Function;
    labelText?: string;
    
    isFormSubmited?: boolean;
    phoneErrorMessage?: any; 
    dialingCodeErrorMessage?: any;
}

interface DAPhoneInputState {
    diallingCode: string | null;

    daillingCodeValue: string;
    phoneNumberValue: string;
}

class DAPhoneInput extends React.Component<DAPhoneInputProps, DAPhoneInputState> {
    constructor(props: DAPhoneInputProps) {
        super(props);

        this.state = {
            diallingCode: null,
            daillingCodeValue: "",
            phoneNumberValue: ""
        }
    }

    isSomeError = () : boolean => {
        return this.props.isFormSubmited !== undefined && this.props.isFormSubmited && this.props.phoneErrorMessage !== null && this.props.phoneErrorMessage !== undefined &&
        this.props.dialingCodeErrorMessage !== null && this.props.dialingCodeErrorMessage !== undefined
    }
    
    onInputChange (e: any) {
        this.setState({phoneNumberValue: e.detail.value});
        if(this.props.onChange) {
            this.props.onChange(this.state.daillingCodeValue, e.detail.value)
        }
    }

    onSelectChange (e: any) {
        let value = e.detail.value.value ?? e.detail.value;
        this.setState({daillingCodeValue: value});
        if(this.props.onChange) {
            this.props.onChange(value, this.state.phoneNumberValue)
        }
    }

    renderCallingCodesInput() {
        if(this.props.diallingCodes) {
            return (<DASelect 
                value={this.state.diallingCode}
                isInvalid={this.props.isFormSubmited && this.props.dialingCodeErrorMessage}
                options={this.props.diallingCodes}
                optionsKey='Id'
                optionsValue='Prefix'
                cssClass='da-phone-input-dialcode'
                onChange={(e: any) => this.onSelectChange(e)} />)
        }
    }

    render() {
        return (
            <div>
                <DAInputLabel labelText={this.props.labelText}/>
                <div className="da-phone-input">

                    {this.renderCallingCodesInput()}

                    <DAInput type="text"
                        cssClass="da-phone-input-main"
                        isInvalid={this.props.isFormSubmited && this.props.phoneErrorMessage}
                        value={this.props.phoneValue}
                        onChange={(e: any) => this.onInputChange(e)} />
                </div>
                <ValidationMessage message={this.props.isFormSubmited ? this.props.phoneErrorMessage : null} />
                <ValidationMessage message={this.props.isFormSubmited ? this.props.dialingCodeErrorMessage : null} />
            </div>
            
            
        );
    }
}

export default DAPhoneInput;