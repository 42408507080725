import React from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { withRouter } from "react-router-dom";
import Home from './Svg/Home';
import NewReservation from './Svg/NewReservation';
import Profile from './Svg/Profile';
import classNames from 'classnames';

interface BottomNavigationProps {
    selected: "dashboard" | "reservation" | "profile";
    text: string;
    history: any;
}

class BottomNavigation extends React.Component<BottomNavigationProps> {
    render() {
        return (
            <div className='bottom-navigation'>
                <button className={classNames('bottom-navigation__button', {'bottom-navigation__button--selected': this.props.selected === "dashboard"})} onClick={() => this.props.history.push('/Dashboard')}>
                    <Home />
                </button>
                <button className={classNames('bottom-navigation__button', {'bottom-navigation__button--selected': this.props.selected === "reservation"})} onClick={() => this.props.history.push('/Reservation')}>
                    <NewReservation />
                </button>
                <button className={classNames('bottom-navigation__button', {'bottom-navigation__button--selected': this.props.selected === "profile"})} onClick={() => this.props.history.push('/Profile')}>
                    <Profile />
                </button>
                <div className='bottom-navigation__line'>
                </div>
            </div>
        );
    }
};

export default withRouter(withIonLifeCycle(BottomNavigation));
