import React from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import DACard from '../Core/DACard';
import RestService from '../../services/RestService';
import { cardOutline } from 'ionicons/icons';
import { getCms } from '../../services/CmsService';

interface DonorCardProps {

}

interface DonorCardState {
    barcode: string | undefined
}

class DonorCard extends React.Component<DonorCardProps, DonorCardState> {
    constructor(props: DonorCardProps) {
        super(props);

        this.state = {
            barcode: undefined
        };
    }

    async ionViewWillEnter () {
        let donorProfile = await RestService.getDonorProfile();
        this.setState({barcode: donorProfile?.Code})
    }

    render() {
        return (
            <DACard icon={cardOutline} title={getCms("dashboard.donorCardTitle")} cardClass="donor-card ion-text-center" smallCard>
                <div className="barcode ion-text-center">
                    { this.state.barcode }
                </div>
                <span className="text-bold flex ion-justify-content-center">{ this.state.barcode }</span>
            </DACard>
        );
    }
};

export default withRouter(withIonLifeCycle(DonorCard));