import React from 'react';
import { IonLoading, IonIcon, IonBadge, IonButton, withIonLifeCycle, IonRow, IonCol, IonImg, IonText } from '@ionic/react';
import { withRouter } from "react-router-dom";
import GetNextReservationResponse from '../models/backendResponses/GetNextReservationResponse';
import Alert from './Alert';
import DAPopup from './Core/DAPopup';
import DATooltip from './Core/DATooltip';
import { closeCircleOutline, calendarClearOutline } from 'ionicons/icons';
import RestService from '../services/RestService';
import GTMService from '../services/GTMService';
import { getFirstNotificationMessage, isSuccess } from '../models/MetaData';
import moment from 'moment';
import FileImg from '../theme/img/File.svg';
import { getCms } from '../services/CmsService';
import AddIcon from './Svg/AddIcon';
import DAButton from './Core/DAButton';
import Notify from './Notifications/component';

interface NextReservationState {
    loading: boolean;
    alertCancellation: boolean;
}

interface NextReservationProps {
    history: any;
    nextReservation: GetNextReservationResponse | null;
    cancelCalback: () => void;
}

class NextReservation extends React.Component<NextReservationProps, NextReservationState> {
    tooltipId = 'cancelReservationTooltip';

    constructor(props: NextReservationProps) {
        super(props);

        this.state = {
            loading: false,
            alertCancellation: false
        };
    }

    async cancelReservation() {
        this.setState({ loading: true });

        var cancelReservationRes = await RestService.cancelReservation(this.props.nextReservation?.ReservationId!);

        if (isSuccess(cancelReservationRes)) {
            GTMService.cancelReservationEvent(this.props.nextReservation?.SubcenterName ?? null);

            this.props.cancelCalback();
            Notify(getCms('dashboard.nextReservation.success'), "success");
        } else {
            Notify(getFirstNotificationMessage(cancelReservationRes) ?? "Cancallation failed");
        }

        this.setState({ loading: false });
    }

    editReservation = () => {

    }

    render() {
        if (this.props.nextReservation === null) {
            return (null);
        }

        return (
            <React.Fragment>
                <IonLoading isOpen={this.state.loading} />

                {this.props.nextReservation.ReservationId === null ? this.noReservation() : this.reservationInfo()}
            </React.Fragment>
        );
    }

    reservationInfo() {
        return (
            <div className='reservation'>
                <IonRow class="ion-align-items-center">
                    <IonCol sizeXs="6" sizeMd='8' className='ion-no-padding'>
                        <IonBadge color="danger">{getCms('dashboard.nextReservation.upcoming')}</IonBadge>
                    </IonCol>

                    <IonCol sizeXs="6" sizeMd='4' className="ion-no-padding ion-text-right">
                        {/* <IonButton disabled color="primary" fill="clear"><IonIcon icon={pencil}></IonIcon></IonButton> */}
                        <IonButton className="auto-height icon-with-text" color="danger" fill="clear" 
                            /* data-tip data-for={this.tooltipId} - Tooltip turned off, doesn't work with shadow dom & transform-style: preserve-3d */
                            onClick={() => this.setState({ alertCancellation: true }) }>
                            <IonIcon icon={closeCircleOutline} onClick={async () => await this.cancelReservation()} />
                            <span>{getCms('common.cancel')}</span>
                        </IonButton>

                        <DATooltip id={this.tooltipId} 
                            showOnHover={true}
                            message={getCms('dashboard.nextReservation.cancelAlertTooltip')}>
                        </DATooltip>
                    </IonCol>
                </IonRow>

                <IonRow className="reservation__reservation-date">
                    <IonCol className="ion-align-items-center d-flex">
                        <IonIcon className="text-color-danger text-bigger with-text" icon={calendarClearOutline}></IonIcon>
                        <IonText className="text-bigger text-color-blue-dark">
                            <strong className="text-capitalize">&nbsp;{moment(this.props.nextReservation!.NextReservationDate).format('dddd D.M. YYYY')}</strong>&nbsp;{moment(this.props.nextReservation!.NextReservationDate).format('HH:mm')}
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className='ion-no-padding'>
                        <Alert type="info" text={getCms('dashboard.nextReservation.warning')} />
                    </IonCol>
                </IonRow>
                <DAPopup open={this.state.alertCancellation}
                    onDismiss={() => this.setState({ alertCancellation: false })}
                    header={getCms('dashboard.nextReservation.cancelAlertHeader')}
                    message={getCms('dashboard.nextReservation.cancelAlertMessage')} 
                    buttons={[
                        {
                            text: getCms('dashboard.nextReservation.cancelAlertBack'),
                            role: 'cancel'
                        },
                        {
                            text: getCms('dashboard.nextReservation.cancelAlertConfirm'),
                            handler: () => {
                                this.cancelReservation();
                            }, 
                            cssClass: 'primary'
                        }
                    ]} />
            </div>
        );
    }

    noReservation() {
        return (
            <div className="flex flex-direction-vertical ion-justify-content-between flex-row-m">
                <div className="flex ion-align-items-center">
                    <IonImg src={FileImg}></IonImg>
                    <span className="text-color-silver">{getCms('dashboard.nextReservation.noNextReservation')}</span>
                </div>
                <div className='flex ion-align-items-center'>
                    <DAButton cssClass="w-100"
                        color="primary"
                        expand="full"
                        size="large"
                        submitOnEnter
                        onClick={() => this.props.history.push('/Reservation')}>
                        <div className='flex ion-align-items-center gap-little font-size-heading-3 line-height-default'>
                            <AddIcon />
                            {getCms('dashboard.nextReservation.createReservationButton')}
                        </div>
                    </DAButton>
                </div>
            </div>
        );
    }
};

export default withRouter(withIonLifeCycle(NextReservation));
