import React from 'react';
import { getCms } from '../services/CmsService';


class FinancialReward extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div id='RewardsCms' dangerouslySetInnerHTML={{__html: getCms('login.rewards')}} />
            </React.Fragment>
        );
    }
};

export default FinancialReward;