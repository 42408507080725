import React from 'react';
import { IonLoading, withIonLifeCycle } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { checkLoggedUser } from '../services/UserService';
import GetDonorProfileResponse from '../models/backendResponses/GetDonorProfileResponse'
import RestService from '../services/RestService'
import ProfileCard from '../components/Card/ProfileCard';
import { getCms } from '../services/CmsService';
import BottomNavigation from '../components/BottomNavigation';
import DAPage from '../components/Core/DAPage';
import DAHeading from '../components/Core/DAHeading';

interface ProfileState {
    loading: boolean;
    donorProfile: GetDonorProfileResponse | null;
}

interface ProfileProps {
    history: any
}

class Profile extends React.Component<ProfileProps, ProfileState> {
    constructor(props: ProfileProps) {
        super(props);

        this.state = {
            loading: false,
            donorProfile: null
        };
    }

    async ionViewWillEnter() {
        checkLoggedUser();

        this.setState({ loading: true, donorProfile: null });

        let donorProfile = await RestService.getDonorProfile();
        
        this.setState({
            loading: false,
            donorProfile
        });
    }

    changeLocationClick() {
        this.props?.history.push("/ChangeLocation");
    }
    
    changePswClick() {
        this.props?.history.push("/ChangePassword");
    }

    goBack() {
        this.props?.history.push("/Dashboard");
    }

    render() {
        return (
            <DAPage containerClass='page-profile page-with-navigation' hideHeaderOnMobile>
                <IonLoading isOpen={this.state.loading} />                    
                
                <div className="page-profile__content flex flex-direction-vertical">
                    <div>
                        <DAHeading onClick={this.goBack.bind(this)} 
                            text={getCms("donor-profile.title")}
                            margin='large' />
                    </div>

                    <ProfileCard donorProfile={this.state.donorProfile}
                        onChangeLocation={this.changeLocationClick.bind(this)}
                        onChangePsw={this.changePswClick.bind(this)} />
                </div>

                <BottomNavigation selected="profile" />
            </DAPage>
        );
    }
};

export default withRouter(withIonLifeCycle(Profile));