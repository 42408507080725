import React from 'react';
import { IonLoading, withIonLifeCycle } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { checkLoggedUser } from '../services/UserService';
import { ValidatableInput, getErrorMessagePassword } from "../services/Validation";
import KyesIcon from '../components/Svg/KeysIcon';
import DAInput from '../components/Core/DAInput';
import ValidationMessage from '../components/ValidationMessage';
import ResetPasswordResponse from '../models/backendResponses/ResetPasswordResponse'
import RestService from '../services/RestService'
import { isSuccess, getFirstNotificationMessage } from '../models/MetaData'
import { getCms } from '../services/CmsService';
import DAPage from '../components/Core/DAPage';
import DAHeading from '../components/Core/DAHeading';
import DACard from '../components/Core/DACard';
import DAButton from '../components/Core/DAButton';
import Notify from '../components/Notifications/component';

interface ChangePasswordState {
    loading: boolean;
    formSubmitted: boolean;
    oldPassword: ValidatableInput;    
    password: ValidatableInput;
    password2: ValidatableInput;
    changePasswordErrorMessage: string | null;
}

class ChangePassword extends React.Component<any, ChangePasswordState> {
    constructor(props: any) {
        super(props);

        this.state = this.getInitForm();
    }

    ionViewWillEnter() {
        checkLoggedUser();

        this.setState(this.getInitForm());
    }

    getInitForm() {
        return {
            loading: false,
            formSubmitted: false,
            oldPassword: new ValidatableInput(true, getCms('change-password.validationMessages.enterOldPassword')),
            password: new ValidatableInput(true, getCms('change-password.validationMessages.enterPassword'), '', (value) => getErrorMessagePassword(value, this.state.password2.value)),
            password2: new ValidatableInput(true, getCms('change-password.validationMessages.enterPassword')),
            changePasswordErrorMessage: null
        };
    }

    async changePassword() {
        this.setState({ formSubmitted: true });

        if (!this.isValid()) {
            return;
        }

        this.setState({ loading: true });

        let res: ResetPasswordResponse | null;
        try {
            res = await RestService.changePassword(this.state.oldPassword.value, this.state.password.value);
        }
        catch (error) {
            console.error(error);
            res = null;
        }
            
        if (isSuccess(res?.MetaData)) {
            this.setState({ 
                formSubmitted: false,
                oldPassword: this.state.oldPassword.setValue(''),
                password: this.state.password2.setValue(''),
                password2: this.state.password2.setValue(''),
                loading: false
            });

            Notify(getCms('change-password.success'), "success");
            this.goBack();
        } else {
            this.setState({ 
                loading: false,
                changePasswordErrorMessage: getFirstNotificationMessage(res?.MetaData) ?? "Change failed"
            });
        }        
    }

    isValid(): boolean {
        return this.state.oldPassword.isValid()
            &&this.state.password.isValid()
            && this.state.password2.isValid();
    }

    goBack() {
        this.props.history.push("/Profile");
    }

    render() {
        return (
            <DAPage hideHeaderOnMobile>
                <IonLoading isOpen={this.state.loading} />

                <div className="flex ion-align-items-center flex-direction-vertical">
                    <DAHeading onClick={this.goBack.bind(this)} 
                        text={getCms("change-password.title")} 
                        hideHeaderOnMobile />

                    <DACard cardClass="card card-action card-top-fab-btn dots-bg-start-bottom"
                        title={getCms('change-password.title')}
                        icon={<KyesIcon />}
                        showTitleOnMobileOnly>

                        <div>
                            <div className='flex flex-direction-vertical gap-increased gap-great-m'>
                                <DAInput labelText={getCms('change-password.oldPassword')}
                                    type="password"
                                    value={this.state.oldPassword.value}
                                    onChange={(e: any) => this.setState({ oldPassword: this.state.oldPassword.setValue(e.detail.value!) })}
                                    isFormSubmited={this.state.formSubmitted}
                                    errorMessage={this.state.oldPassword.getErrorMessage()} />

                                <DAInput labelText={getCms('change-password.password')}
                                    type="password"
                                    value={this.state.password.value}
                                    onChange={(e: any) => this.setState({ password: this.state.password.setValue(e.detail.value!) })}
                                    isFormSubmited={this.state.formSubmitted}
                                    errorMessage={this.state.password.getErrorMessage()} />

                            </div>

                            <div className="line-height-increased text-color-silver mt-reduced mb-increased">
                                {getCms('change-password.passwordInfo')}
                            </div>

                            <div>
                                <DAInput labelText={getCms('recover-password.passwordRepeat')}
                                    type="password"
                                    value={this.state.password2.value}
                                    onChange={(e: any) => this.setState({ password2: this.state.password2.setValue(e.detail.value!) })}
                                    isFormSubmited={this.state.formSubmitted}
                                    errorMessage={this.state.password2.getErrorMessage()} />

                                <ValidationMessage message={this.state.changePasswordErrorMessage} />
                            </div>

                            <DAButton 
                                disabled={this.state.password.value.length === 0 || this.state.password2.value.length === 0} 
                                cssClass="mt-increased mt-great-m" 
                                color="danger" 
                                expand="block"
                                submitOnEnter
                                size="large"
                                onClick={async () => await this.changePassword()}>
                                    {getCms('change-password.changePassword')}
                            </DAButton>
                        </div>
                    </DACard>
                </div>
            </DAPage>
        );
    }
};

export default withRouter(withIonLifeCycle(ChangePassword));