import React from 'react';

class DashboardIcon extends React.Component {
    render() {
        return (
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='new-reservation-icon'>
                <path d="M9.3 16.75H2.05C1.55 16.75 1.125 16.575 0.775 16.225C0.425 15.875 0.25 15.45 0.25 14.95V4.05C0.25 3.55 0.425 3.125 0.775 2.775C1.125 2.425 1.55 2.25 2.05 2.25H3.25V0.25H4.75V2.25H11.25V0.25H12.75V2.25H13.95C14.45 2.25 14.875 2.425 15.225 2.775C15.575 3.125 15.75 3.55 15.75 4.05V10.3C15.6167 10.2833 15.4917 10.2707 15.375 10.262C15.2583 10.254 15.1333 10.25 15 10.25C14.8667 10.25 14.7417 10.254 14.625 10.262C14.5083 10.2707 14.3833 10.2833 14.25 10.3V6.75H1.75V14.95C1.75 15.0333 1.77933 15.1043 1.838 15.163C1.896 15.221 1.96667 15.25 2.05 15.25H9.3C9.28333 15.3833 9.27067 15.5083 9.262 15.625C9.254 15.7417 9.25 15.8667 9.25 16C9.25 16.1333 9.254 16.2583 9.262 16.375C9.27067 16.4917 9.28333 16.6167 9.3 16.75ZM14.25 19.75V16.75H11.25V15.25H14.25V12.25H15.75V15.25H18.75V16.75H15.75V19.75H14.25Z" fill="white" />
            </svg>
        );
    }
}

export default DashboardIcon;