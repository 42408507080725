import React from 'react';

class ProfileIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M7,10L12,15L17,10H7Z" /></svg>
        );
    }
}

export default ProfileIcon;