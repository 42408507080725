import React from 'react';
import { IonLoading, IonCheckbox, IonCol, IonItem, withIonLifeCycle, IonRow, isPlatform } from '@ionic/react';
import ValidationMessage from '../components/ValidationMessage';
import GdprLink from '../components/GdprLink';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ValidatableInput, ValidatableCheckbox, getErrorMessageEmail, getErrorMessagePassword, getErrorMessageDialingCode, getErrorMessagePhone } from "../services/Validation";
import RestService from '../services/RestService'
import { getFirstNotificationMessage, isSuccess } from '../models/MetaData'
import { setLoggedUser } from '../services/UserService';
import GTMService from '../services/GTMService';
import LoginResponse from '../models/backendResponses/LoginResponse';
import RegisterNewUserResponse from '../models/backendResponses/RegisterNewUserResponse';
import { SubcenterDetail } from '../models/backendResponses/GetSubcenterDetailsResponse';
import GetCallingCodesResponse, { CountryCallingCode } from '../models/backendResponses/GetCallingCodesResponse';
import DAInput from './Core/DAInput';
import DAPhoneInput from './Core/DAPhoneInput';
import SubcenterChooser from '../components/SubcenterChooser';
import { getCms } from '../services/CmsService';
import getTrimmedData from '../helpers/GetTrimmedData';
import { LoginCredential } from '../models/LoginCredential';
import DAButton from './Core/DAButton';
import qs from 'qs';

interface RegisterNewState {
    loading: boolean;
    formSubmitted: boolean;
    email: ValidatableInput;
    password: ValidatableInput;
    password2: ValidatableInput;
    firstName: ValidatableInput;
    lastName: ValidatableInput;
    birthdate: ValidatableInput;
    dialingCode: ValidatableInput;
    phone: ValidatableInput;
    gdprAgreement: ValidatableCheckbox;
    username: ValidatableInput;
    selectedSubcenter: SubcenterDetail | null;
    registrationErrorMessage: string | null;
    diallingCodes: Array<CountryCallingCode> | null;
}

interface RegisterNewProps extends RouteComponentProps {
    history: any;
    subcenters: Array<SubcenterDetail> | null;
}

class RegisterNew extends React.Component<RegisterNewProps, RegisterNewState> {
    constructor(props: RegisterNewProps) {
        super(props);

        let center = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).center as string;

        this.state = {
            loading: false,
            formSubmitted: false,
            email: new ValidatableInput(false, getCms('registration.validationMessages.enterEmail'), '', (value) => getErrorMessageEmail(value, this.state.phone.value)),
            password: new ValidatableInput(true, getCms('registration.validationMessages.enterPassword'), '', (value) => getErrorMessagePassword(value, this.state.password2.value)),
            password2: new ValidatableInput(true, getCms('registration.validationMessages.enterPassword')),
            firstName: new ValidatableInput(true, getCms('registration.validationMessages.enterFirstName')),
            lastName: new ValidatableInput(true, getCms('registration.validationMessages.enterLastName')),
            birthdate: new ValidatableInput(true, getCms('registration.validationMessages.birthdate')),
            dialingCode: new ValidatableInput(false, getCms('registration.validationMessages.diallingCode'), '', (value) => getErrorMessageDialingCode(value, this.state.phone.value)),
            phone: new ValidatableInput(false, getCms('registration.validationMessages.enterPhone'), '', (value) => getErrorMessagePhone(value, this.state.email.value)),
            gdprAgreement: new ValidatableCheckbox(true, getCms('registration.validationMessages.gdprAgreementRequired')),
            username: new ValidatableInput(true, getCms('registration.validationMessages.enterUsername')),
            selectedSubcenter: this.props.subcenters?.find(s => s.Id === parseInt(center)) ?? null,
            registrationErrorMessage: null,
            diallingCodes: null
        };
    }

    async componentDidMount() {
        let codesResponse: GetCallingCodesResponse | null = null;

        try {
            this.setState({ loading: true });

            codesResponse = await RestService.getCountryCallingCodes();

            if (codesResponse !== null && codesResponse?.CountryCodeList.length) {
                this.setState({
                    diallingCodes: codesResponse.CountryCodeList.map(code => getTrimmedData(code)),
                    loading: false
                });
            } else {
                this.setState({ loading: false });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({ loading: false });
        }
    }

    async registerNewDonor() {
        this.setState({ formSubmitted: true });

        if (!this.isValid()) {
            return;
        }

        this.setState({ loading: true });

        let res: RegisterNewUserResponse | null;

        try {
            res = await RestService.registerNewUser(
                this.state.username.value,
                this.state.password.value,
                this.state.firstName.value,
                this.state.lastName.value,
                this.state.birthdate.value,
                this.state.dialingCode.value,
                this.state.phone.value,
                this.state.email.value,
                this.state.selectedSubcenter!.Id);
        }
        catch (error) {
            console.error(error);
            res = null;
        }

        if (isSuccess(res?.MetaData)) {
            GTMService.registrationEvent(this.state.selectedSubcenter!.Name ?? null);
            let thankYouUrl = await RestService.getSetting('DonorApp.ThankYouUrl.Registration', null);
            if (thankYouUrl.SettingValue?.length) window.open(thankYouUrl.SettingValue, '_blank');

            let resLogin: LoginResponse | null;

            try {
                resLogin = await RestService.login(
                    this.state.username.value,
                    this.state.password.value);
            }
            catch (error) {
                console.error(error);
                resLogin = null;
            }

            if (resLogin?.Token) {
                await setLoggedUser(resLogin, new LoginCredential(this.state.username.value, this.state.password.value));
                this.setState({ loading: false });
                window.location.replace("/Dashboard");
            } else {
                this.setState({
                    loading: false,
                    registrationErrorMessage: getFirstNotificationMessage(res?.MetaData) ?? "Login failed"
                });
            }
        } else {
            this.setState({
                loading: false,
                registrationErrorMessage: getFirstNotificationMessage(res?.MetaData) ?? "Registration failed"
            });
        }
    }

    handleInputFocus = (e: any) => {

        if (isPlatform('ios')) {
            setTimeout(() => {
                e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
        }
    }

    isValid(): boolean {
        return this.state.email.isValid()
            && this.state.password.isValid()
            && this.state.password2.isValid()
            && this.state.firstName.isValid()
            && this.state.lastName.isValid()
            && this.state.birthdate.isValid()
            && this.state.dialingCode.isValid()
            && this.state.phone.isValid()
            && this.state.gdprAgreement.isValid()
            && this.state.username.isValid()
            && this.state.selectedSubcenter !== null;
    }

    subYear = (insertedDate: Date, year: number): Date => {
        insertedDate.setFullYear(new Date().getFullYear() - year);
        return insertedDate;
    }

    render() {
        return (
            <React.Fragment>
                <IonLoading isOpen={this.state.loading} />

                <div className="register-new">
                    <div className="flex flex-direction-vertical">
                        <div className="flex flex-direction-vertical">
                            {/* <IonCol sizeXs="12" className="ion-margin-top ion-no-padding">
                                <IonText>{getCms('registration.slogan')}</IonText>
                            </IonCol> */}

                            <h2 className="ion-text-left ion-no-margin text-color-black mb-regular">{getCms('registration.formTitlePersonal')}</h2>
                        </div>

                        <div className='page-registration__form mb-big mb-great-xl'>
                            <DAInput type="text"
                                labelText={getCms('registration.firstName')}
                                value={this.state.firstName.value}
                                onChange={(e: any) => this.setState({ firstName: this.state.firstName.setValue(e.detail.value!) })}
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.firstName.getErrorMessage()}
                                onFocus={this.handleInputFocus}
                            />

                            <DAInput type="text"
                                labelText={getCms('registration.lastName')}
                                value={this.state.lastName.value}
                                onChange={(e: any) => this.setState({ lastName: this.state.lastName.setValue(e.detail.value!) })}
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.lastName.getErrorMessage()}
                                onFocus={this.handleInputFocus}
                            />

                            <DAInput type="date"
                                labelText={getCms('registration.birthdate')}
                                value={this.state.birthdate.value}
                                onChange={(e: Date) => this.setState({ birthdate: this.state.birthdate.setValue(e?.toISOString()) }!)}
                                isFormSubmited={this.state.formSubmitted}
                                maxDate={new Date()}
                                errorMessage={this.state.birthdate.getErrorMessage()}
                                dropdownMode="scroll"
                                yearDropdownItemNumber={100}
                                minDate={this.subYear(new Date(), 90)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                onFocus={this.handleInputFocus}
                            />

                            <DAPhoneInput
                                labelText={getCms('registration.phone')}
                                phoneValue={this.state.phone.value}
                                diallingCodes={this.state.diallingCodes}
                                dialingCodeValue={this.state.dialingCode.value}
                                onChange={(diallingCode: CountryCallingCode, phoneNumber: string) => {
                                    this.setState({
                                        phone: this.state.phone.setValue(phoneNumber!),
                                        dialingCode: this.state.dialingCode.setValue(diallingCode.Id.toString()),
                                        // TODO: is it really desirable to put dialling code into the username?
                                        username: this.state.email.value ? this.state.username.setValue(this.state.email.value) : this.state.username.setValue(diallingCode.Prefix + phoneNumber!)
                                    })
                                }}
                                isFormSubmited={this.state.formSubmitted}
                                phoneErrorMessage={this.state.phone.getErrorMessage()}
                                dialingCodeErrorMessage={this.state.dialingCode.getErrorMessage()} />

                            <DAInput type="email"
                                labelText={getCms('registration.username')}
                                value={this.state.email.value}
                                onChange={(e: any) => {
                                    this.setState({
                                        email: this.state.email.setValue(e.detail.value!),
                                        username: this.state.username.setValue(e.detail.value!)
                                    })
                                }}
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.email.getErrorMessage()}
                                onFocus={this.handleInputFocus}
                            />
                        </div>

                        <div>
                            <h2 className="ion-text-left text-color-black mb-regular">{getCms('registration.formTitlePassword')}</h2>
                        </div>

                        <div className='page-registration__form mb-big mb-great-xl'>
                            <DAInput type="password"
                                labelText={getCms('registration.password')}
                                value={this.state.password.value}
                                onChange={(e: any) => this.setState({ password: this.state.password.setValue(e.detail.value!) })}
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.password.getErrorMessage()}
                                tooltipContent={[
                                    getCms('registration.validationMessages.weekPasswordLength'),
                                    getCms('registration.validationMessages.weekPasswordCapital'),
                                    getCms('registration.validationMessages.weekPasswordNumber')
                                ]}
                                onFocus={this.handleInputFocus}
                            />

                            <DAInput type="password"
                                labelText={getCms('registration.repeatPassword')}
                                value={this.state.password2.value}
                                onChange={(e: any) => this.setState({ password2: this.state.password2.setValue(e.detail.value!) })}
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.password2.getErrorMessage()}
                                onFocus={this.handleInputFocus}
                            />
                        </div>

                        <div className="mb-big mb-great-xl">
                            <SubcenterChooser
                                formSubmitted={this.state.formSubmitted}
                                subcenters={this.props.subcenters}
                                selectedCenter={this.state.selectedSubcenter}
                                subcenterSelected={(value: SubcenterDetail) => { this.setState({ selectedSubcenter: value }); } } />
                        </div>

                        <div className='flex ion-align-items-center flex-direction-vertical mb-big gap-medium'>
                            <IonItem className='full-height ion-no-padding'>
                                <IonCheckbox
                                    className="ion-no-margin mr-medium"
                                    color="primary"
                                    slot="start"
                                    onIonChange={e => this.setState({ gdprAgreement: this.state.gdprAgreement.setValue(e.detail.checked) })}>
                                </IonCheckbox>

                                <GdprLink subcenter={this.state.selectedSubcenter} />
                            </IonItem>

                            <ValidationMessage message={this.state.formSubmitted ? this.state.gdprAgreement.getErrorMessage() : null} />
                        </div>

                        <div>
                            <ValidationMessage message={this.state.registrationErrorMessage} />
                        </div>

                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6" sizeLg="4" sizeXl="3">
                                <DAButton color="danger"
                                    expand="full"
                                    submitOnEnter
                                    text={getCms('registration.register')}
                                    onClick={async () => await this.registerNewDonor()} />
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

export default withRouter(withIonLifeCycle(RegisterNew));
