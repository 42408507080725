import React from 'react';

class DashboardIcon extends React.Component {
    render() {
        return (
            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='profile-icon'>
                <path d="M18.5063 19.7809H18.8062V19.4809V18.0371C18.8062 17.8178 18.6788 17.6249 18.5447 17.4764C18.4019 17.3183 18.2041 17.1588 17.97 17.0026C17.5 16.6888 16.8433 16.3612 16.0729 16.0641C14.532 15.4698 12.4935 14.9809 10.5 14.9809C8.50646 14.9809 6.46802 15.4698 4.92712 16.0641C4.15675 16.3612 3.49997 16.6888 3.02998 17.0026C2.79587 17.1588 2.59806 17.3183 2.45526 17.4764C2.32116 17.6249 2.19375 17.8178 2.19375 18.0371V19.4809V19.7809H2.49375H18.5063ZM13.5563 6.22461C13.5563 4.53642 12.1882 3.16836 10.5 3.16836C8.81181 3.16836 7.44375 4.53642 7.44375 6.22461C7.44375 7.9128 8.81181 9.28086 10.5 9.28086C12.1882 9.28086 13.5563 7.9128 13.5563 6.22461ZM5.55 6.22461C5.55 3.48967 7.76506 1.27461 10.5 1.27461C13.2349 1.27461 15.45 3.48967 15.45 6.22461C15.45 8.95955 13.2349 11.1746 10.5 11.1746C7.76506 11.1746 5.55 8.95955 5.55 6.22461ZM0.3 18.0371C0.3 17.2745 0.679649 16.5843 1.35129 15.9644C2.02497 15.3426 2.97225 14.8121 4.04742 14.3814C6.19824 13.5198 8.78531 13.0871 10.5 13.0871C12.2147 13.0871 14.8018 13.5198 16.9526 14.3814C18.0277 14.8121 18.975 15.3426 19.6487 15.9644C20.3204 16.5843 20.7 17.2745 20.7 18.0371V21.6746H0.3V18.0371Z" fill="#0C9682" stroke="white" strokeWidth="0.6" />
            </svg>
        );
    }
}

export default DashboardIcon;