import React from 'react';
import { IonLoading, withIonLifeCycle } from '@ionic/react';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { checkLoggedUser, getLoggedUser } from '../services/UserService';
import { ValidatableDate, ValidatableNumber } from "../services/Validation";
import ValidationMessage from '../components/ValidationMessage';
import { getFirstNotificationMessage, isSuccess } from '../models/MetaData';
import RestService from '../services/RestService';
import { Slot } from '../models/backendResponses/GetSlotsResponse';
import GetSubcenterDetailsResponse, { SubcenterDetail } from '../models/backendResponses/GetSubcenterDetailsResponse';
import DAInput from '../components/Core/DAInput';
import DACard from '../components/Core/DACard';
import DASelect from '../components/Core/DASelect';
import GTMService from '../services/GTMService';
import { getCms } from '../services/CmsService';
import DAPage from '../components/Core/DAPage';
import DAHeading from '../components/Core/DAHeading';
import DAButton from '../components/Core/DAButton';
import Alert from '../components/Alert';
import Notify from '../components/Notifications/component';

interface ReservationState {
    loading: boolean;
    formSubmitted: boolean;
    date: ValidatableDate;
    dateIsOpen: boolean;
    slotId: ValidatableNumber;
    reservationErrorMessage: string | null;
    slots: Array<Slot> | null;
    times: Array<Slot> | null;
    subcenterDetails: SubcenterDetail | null;
}

interface ReservationProps {
    history: any;
}

class Reservation extends React.Component<ReservationProps, ReservationState> {
    constructor(props: ReservationProps) {
        super(props);

        this.state = {
            loading: false,
            formSubmitted: false,
            date: new ValidatableDate(true, getCms('reservation.validationMessages.enterDate')),
            dateIsOpen: false,
            slotId: new ValidatableNumber(true, getCms('reservation.validationMessages.enterTime')),
            reservationErrorMessage: null,
            slots: null,
            times: null,
            subcenterDetails: null
        };
    }

    async ionViewWillEnter() {
        checkLoggedUser();
        
        this.setState({ 
            loading: true,
            formSubmitted: false,
            date: this.state.date.setValue(null),
            slotId: this.state.slotId.setValue(null),
            times: null,
            reservationErrorMessage: null
        });

        let slots: Array<Slot> | null = null;
        let subcenters: GetSubcenterDetailsResponse | null = null;

        try {
            let slotsResponse = await RestService.getSlots(getLoggedUser()?.MobileUser!);
            if (isSuccess(slotsResponse?.MetaData)) {
                slots = slotsResponse?.Slots!;
            } else {
                console.error(getFirstNotificationMessage(slotsResponse?.MetaData));
                slots = null;
            }

            subcenters = await RestService.getSubcenterDetails();
        }
        catch (error) {
            console.error(error);
        }

        this.setState({
            loading: false,
            slots,
            subcenterDetails: subcenters?.Subcenters.find((s: SubcenterDetail) => s.Id === getLoggedUser()?.MobileUser.DefaultSubcenterId!) ?? null,
        });
    }

    ionViewWillLeave() {
        this.setState({ 
            loading: false,
            reservationErrorMessage: null
        });
    }

    async createNewReservation() {
        this.setState({ formSubmitted: true });

        if (!this.isValid()) {
            return;
        }

        this.setState({ loading: true });

        var createReservationRes = await RestService.createReservation(this.state.slotId.number!);

        if (isSuccess(createReservationRes.MetaData)) {
            this.setState({
                loading: false,
                reservationErrorMessage: null
            });

            GTMService.createReservationEvent(this.state.subcenterDetails?.Name ?? null, getLoggedUser()?.Donor.IsFirstVisit ?? false);
            this.props.history.push("/Dashboard");
            Notify(getCms('reservation.success'), "success");
            let thankYouUrl = await RestService.getSetting('DonorApp.ThankYouUrl.Reservation', null);
            if (thankYouUrl.SettingValue?.length) window.open(thankYouUrl.SettingValue, '_blank');
        } else {
            this.setState({
                loading: false,
                reservationErrorMessage: getFirstNotificationMessage(createReservationRes?.MetaData) ?? "Reservation failed"
            });
        }
    }

    isValid(): boolean {
        return this.state.date.isValid()
            && this.state.slotId.isValid();
    }

    dateClick(date: Date) {
        let times = this.state.slots?.filter(s => this.equalDate(s.TimeFrom, date) && s.SlotCapacityTotal > s.SlotCapacityUsed);

        this.setState({
            date: this.state.date.setValue(date),
            dateIsOpen: false,
            times: times!,
            slotId: this.state.slotId.setValue(null),
        });
    }

    timeClick(event: any) {
        this.setState({
            slotId: this.state.slotId.setValue(event.detail.value?.Id),
        });
    }

    isDateAvailable(date: Date) {
        if (this.state.slots === null) {
            return false;
        }

        return this.state.slots.find(s => this.equalDate(s.TimeFrom, date) && s.SlotCapacityTotal > s.SlotCapacityUsed);
    }

    getAvailableDate = ():Array<Date> | undefined => {
        if (this.state.slots === null) {
            return undefined;
        }

        return this.state.slots.filter(s => s.SlotCapacityTotal > s.SlotCapacityUsed).map(item => item.TimeFrom);
    }

    equalDate = (date1: Date, date2: Date):boolean => {
        if(!date1 || !date2) {
            return false
        }
        return date1.getFullYear() === date2.getFullYear()
            && date1.getMonth() === date2.getMonth()
            && date1.getDate() === date2.getDate();
    }

    goBackBtn = () => {
        this.setState({ loading: true });
        this.props.history.push("/Dashboard");
    }

    render() {
        return (
            <DAPage hideHeaderOnMobile>
                <IonLoading isOpen={this.state.loading} />

                <div className="flex ion-align-items-center flex-direction-vertical">
                    <DAHeading onClick={() => { this.goBackBtn() }} 
                        text={getCms("reservation.title")} 
                        hideHeaderOnMobile />

                    <DACard cardClass="card card-action dots-bg-end-top dots-bg-start-bottom"
                        title={getCms('reservation.title')}
                        showTitleOnMobileOnly>

                        <div className="mb-increased">
                            <DAInput    type="date"
                                        labelText={getCms('reservation.date')}
                                        value={this.state.date.date}
                                        isFormSubmited={this.state.formSubmitted}
                                        availableDates={this.getAvailableDate()}
                                        onChange={(date: Date) => this.dateClick(date)}
                                        minCalendarDetail="month"
                                        errorMessage={this.state.date.getErrorMessage()}
                                        />
                        </div>

                        <div className="mb-increased">
                            <DASelect   labelText={getCms('reservation.time')}
                                        optionsValue="TimeFrom"
                                        optionsKey="Id"
                                        value={null}
                                        disabled={this.state.times === null}
                                        onChange={(e: any) => this.timeClick(e)}
                                        formateValue={(data: Date) => {
                                            return data.toLocaleTimeString(i18next.language, {hour: 'numeric', minute:'2-digit'});
                                        }}
                                        options={this.state.times}
                                        isFormSubmited={this.state.formSubmitted}
                                        errorMessage={this.state.slotId.getErrorMessage()}/>
                        </div>

                        <div className='mb-increased'>
                            <ValidationMessage message={this.state.reservationErrorMessage} />
                        </div>
                        
                        <div className='mb-big mb-increased-m'>
                            <Alert type="info" text={getCms('dashboard.nextReservation.warning')} />
                        </div>

                        <DAButton color="danger"
                            expand="block"
                            submitOnEnter
                            size="large"
                            onClick={async () => await this.createNewReservation()}>
                            {getCms('reservation.createNewReservation')}
                        </DAButton>
                    </DACard>
                </div>
            </DAPage>
        );
    }
};

export default withRouter(withIonLifeCycle(Reservation));