import { BaseEnum } from './BaseEnum';

export default class DonorType extends BaseEnum
{
    static FirstTimer = new DonorType(1, "firsttimer", "registration.registerNewOption");
    static AlreadyDonor = new DonorType(2, "alreadydonor", "registration.registerExistingOption");

    static ToList = () => {
        return [
            DonorType.FirstTimer,
            DonorType.AlreadyDonor
        ]
    }
}
