import React from 'react';
import { IonCol, IonIcon, IonRow, withIonLifeCycle } from '@ionic/react';
import DACard from '../Core/DACard';
import RestService from '../../services/RestService';
import { call, mail } from 'ionicons/icons';
import { getLoggedUser } from '../../services/UserService';
import { getCms } from '../../services/CmsService';

interface ContactCardProps {

}

interface ContactCardState {
    phone: string | undefined;
    email: string | undefined;
}

class ContactCard extends React.Component<ContactCardProps, ContactCardState> {
    constructor(props: ContactCardProps) {
        super(props);

        this.state = {
            phone: undefined,
            email: undefined
        };
    }

    async ionViewWillEnter() {
        let subCenterList = await RestService.getSubcenterDetails();
        let userSubcenterId = getLoggedUser()?.MobileUser.DefaultSubcenterId;
        let userSubcenter = subCenterList?.Subcenters.find(subcenter => subcenter.Id === userSubcenterId);

        this.setState({ phone: userSubcenter?.Phone, email: userSubcenter?.Email });
    }

    render() {
        if (this.state.phone || this.state.email) {
            return (
                <DACard title={getCms("dashboard.contactUs")} smallCard>
                    {this.state.phone ? (<IonRow><IonCol><IonIcon icon={call} className="inline-text text-color-secondary-main" />&nbsp;<span>{this.state.phone}</span></IonCol></IonRow>) : ""}
                    {this.state.email ? (<IonRow><IonCol><IonIcon icon={mail} className="inline-text text-color-secondary-main" />&nbsp;<span>{this.state.email}</span></IonCol></IonRow>) : ""}
                    <div dangerouslySetInnerHTML={{ __html: getCms('dashboard.openingHours') }} />
                </DACard>
            );
        }
        return "";
    }
};

export default withIonLifeCycle(ContactCard);