import { getCms } from "../services/CmsService";

export class BaseEnum {
    key: number | string;
    value: number | string;
    translationKey: string;
    
    get translation(): string {
        return getCms(this.translationKey);
    }

    constructor(key: number | string, value: number | string, translationKey: string) {
        this.key = key;
        this.value = value;
        this.translationKey = translationKey;
    }

    toJson = () => {
        return {
            key: this.key,
            value: this.value,
            translation: this.translation
        }
    }
}