import { Storage } from '@ionic/storage';
import LoginResponse from '../models/backendResponses/LoginResponse'
import { LoginCredential } from '../models/LoginCredential';
import RestService from '../services/RestService'

const storage = new Storage();
const token_key = 'ps_token';
const login_key = 'ps_login';
const password_key = 'ps_password';
let token: string | null;
let loginResponse: LoginResponse | null = null;

export async function initStorage() {
    await storage.create();

    token = await storage.get(token_key);
    
    if (token) {
        loginResponse = await RestService.getUserContext();
        if (loginResponse === null) {
            logoutUser();
        }
    }
}

export function getToken(): string | null {
    return token;    
}

export async function setLoggedUser(response: LoginResponse, credential: LoginCredential) {
    await storage.set(token_key, response.Token);
    await saveCredentail(credential);
    token = response.Token;
    loginResponse = response;
}

export async function logoutUser() {
    await storage.remove(token_key);
    loginResponse = null;
    window.location.replace("/");
}

export function getLoggedUser(): LoginResponse | null {
    return loginResponse;
}

export function setDefaultSubcenterId(subcenterId: number) {
    if (loginResponse != null) {
        loginResponse.MobileUser.DefaultSubcenterId = subcenterId;
    }
}

export function isLogged(): boolean {
    return loginResponse?.Donor !== null && loginResponse?.Donor !== undefined;
}

export function checkLoggedUser() {
    if (!isLogged()) {
        window.location.replace("/");
    }
}

export async function saveCredentail(credential: LoginCredential) {
    await storage.set(login_key, credential.login);
    await storage.set(password_key, credential.password);
}

export async function getSavedCredential(): Promise<LoginCredential | null> {
    let login = await storage.get(login_key);
    let password = await storage.get(password_key);
    
    if (login && password) {
        return new LoginCredential(login, password);
    }

    return null;
}