import React from 'react';
import { IonFabButton } from '@ionic/react';
import  ArrowBackIcon  from '../Svg/ArrowBack';
import { getCms } from '../../services/CmsService';

interface DABackArrowBtnProps {
    onClick: Function;
    cssClass?: string;
}

class DABackArrowBtn extends React.Component<DABackArrowBtnProps> {
    render() {
        return (
            <IonFabButton title={getCms('common.back')} 
                className={`inline-block back-arrow-btn ${this.props.cssClass ?? ''}`} color="light" 
                onClick={() => this.props.onClick()}>
                <ArrowBackIcon />
            </IonFabButton>
        );
    }
};

export default DABackArrowBtn;