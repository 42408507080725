import React from 'react';

class CheckmarkIcon extends React.Component {
    render() {
        return (
            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.32727 8.51317L1.08182 5.26772L0 6.34954L4.32727 10.6768L13.6 1.40408L12.5182 0.322266L4.32727 8.51317Z" />
            </svg>
        );
    }
}

export default CheckmarkIcon;