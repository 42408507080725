import { Device, DeviceInfo } from '@capacitor/device';

export let device:DeviceInfo;

export function isMobileDevice(): boolean {
    return device.platform !== 'web';
}

export function isDestopDevice(): boolean {
    return device.platform === 'web';
}

export function isDev(): boolean {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export async function initPlatform() {
    await Device.getInfo().then((d) => {
        device = d;

        if (isDev()) {
            console.info('Platform Service initialized, device info:', d);
        }
    });
}