import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { 
    IonApp, 
    IonRouterOutlet, 
    withIonLifeCycle,
    IonTabBar, 
    IonTabButton, 
    IonIcon, 
    setupIonicReact,
    IonToast, 
 } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Registration from './pages/Registration';
import Reservation from './pages/Reservation';
import LostPassword from './pages/LostPassword';
import ResetPassword from './pages/RecoverPassword';
import Profile from './pages/Profile';
import ChangePassword from './pages/ChangePassword';
import ChangeLocation from './pages/ChangeLocation';
import { withTranslation } from 'react-i18next';
import FCMService from './services/FCMService';
import LoginIconSVG from './theme/img/Home.svg';
import ProfileIconSVG from './theme/img/Profile.svg';
import packageJson from "../package.json";

import { isMobileDevice } from './services/PlastformService';

/* Theme */
import '@ionic/react/css/core.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/main.scss';

import { isLogged } from './services/UserService';
import PrivateRoute from './helpers/PrivateRoute';
import PublicRoute from './helpers/PublicRoute';
import { getCms } from './services/CmsService';

setupIonicReact ({
    swipeBackEnabled: false
});

interface AppState {
    isLogged: boolean;
    showNotification: boolean;
    notificationMsg: string;
    notifications: any;
}

class App extends React.Component<any, AppState> {
    constructor(props: any) {
        super(props);

        this.state = {
            isLogged: false,
            showNotification: false,
            notificationMsg: '',
            notifications: [],
        }
    }

    caching = () => {
        let version = localStorage.getItem('version');
        if (!version || version !== packageJson.version) {
            if ('caches' in window) {
                caches.keys().then((names) => {
                    names.forEach(name => {
                        caches.delete(name);
                    });
                    
                    window.location.reload();
                });
            }
            localStorage.setItem('version', packageJson.version);
        }
    };

    async componentDidMount() {
        this.caching();
        
        document.title = getCms('common.title');
        
        if (isMobileDevice() && isLogged()) {
            FCMService.init();
        }
    }

    routing() {
        return (
            <IonRouterOutlet>
                <Switch>
                <PublicRoute path="/" component={Login} exact={true} accessAuthUser={false} />
                <PublicRoute path="/Registration" component={Registration} exact={true} accessAuthUser={false} />
                <PublicRoute path="/LostPassword" component={LostPassword} exact={true} accessAuthUser={false} />
                <PublicRoute path="/RecoverPassword/:token" component={ResetPassword} exact={true} accessAuthUser={false} />

                <PrivateRoute path="/Dashboard" component={Dashboard} exact={true} />
                <PrivateRoute path="/Reservation" component={Reservation} exact={true} />
                <PrivateRoute path="/Profile" component={Profile} exact={true} />
                <PrivateRoute path="/ChangePassword" component={ChangePassword} exact={true} />
                <PrivateRoute path="/ChangeLocation" component={ChangeLocation} exact={true} />

                <Route>
                    { isLogged() ? <Redirect to="/Dashboard" /> : <Redirect to="/" /> }
                </Route>
            </Switch>
            </IonRouterOutlet>
        )
    }

    mobileNav() {
        return (
            <IonTabBar slot="bottom">
                <IonTabButton tab="login"  href='/Dashboard'>
                    <IonIcon icon={LoginIconSVG} />
                </IonTabButton>

                <IonTabButton tab="profile" href='/Profile'>
                    <IonIcon icon={ProfileIconSVG} />
                </IonTabButton>
            </IonTabBar>
        )
    }

    render() {
        return (
            <IonApp>
                <IonReactRouter>
                    { this.routing() }
                </IonReactRouter>
                {/* What is this toast for? */}
                <IonToast
                    isOpen={this.state.showNotification}
                    onDidDismiss={() => this.setState({ showNotification: false })}
                    position="top"
                    message={`Notification: ${this.state.notificationMsg}`}
                    duration={4000} />
            </IonApp>
        )
    }
}

export default withTranslation()(withIonLifeCycle(App));
