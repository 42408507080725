import React from 'react';
import { IonLoading, withIonLifeCycle } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { ValidatableInput, getErrorMessagePassword } from "../services/Validation";
import KyesIcon from '../components/Svg/KeysIcon';
import DAInput from '../components/Core/DAInput';
import ValidationMessage from '../components/ValidationMessage';
import ResetPasswordResponse from '../models/backendResponses/ResetPasswordResponse'
import RestService from '../services/RestService'
import { isSuccess, getFirstNotificationMessage } from '../models/MetaData'
import { getCms } from '../services/CmsService';
import DAPage from '../components/Core/DAPage';
import DACard from '../components/Core/DACard';
import DAHeading from '../components/Core/DAHeading';
import DAButton from '../components/Core/DAButton';
import Notify from '../components/Notifications/component';

interface RecoverPasswordState {
    loading: boolean;
    formSubmitted: boolean;
    password: ValidatableInput;
    password2: ValidatableInput;
    recoverErrorMessage: string | null;
}

class RecoverPassword extends React.Component<any, RecoverPasswordState> {
    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            formSubmitted: false,
            password: new ValidatableInput(true, getCms('registration.validationMessages.enterPassword'), '', (value) => getErrorMessagePassword(value, this.state.password2.value)),
            password2: new ValidatableInput(true, getCms('registration.validationMessages.enterPassword')),
            recoverErrorMessage: null
        };
    }

    async recover() {
        this.setState({ formSubmitted: true });

        if (!this.isValid()) {
            return;
        }

        this.setState({ loading: true });

        let res: ResetPasswordResponse | null;
        try {
            res = await RestService.recoverPassword(this.props.match.params.token, this.state.password.value);
        }
        catch (error) {
            console.error(error);
            res = null;
        }
            
        if (isSuccess(res?.MetaData)) {
            this.setState({ loading: false });
            Notify(getCms('recover-password.success'), "success");
            this.props.history.push("/");
        } else {
            this.setState({ 
                loading: false,
                recoverErrorMessage: getFirstNotificationMessage(res?.MetaData) ?? "Recover failed"
            });
        }        
    }

    isValid(): boolean {
        return this.state.password.isValid()
            && this.state.password2.isValid();
    }

    render() {
        return (
            <DAPage hideHeaderOnMobile>
                <IonLoading isOpen={this.state.loading} />
                
                <div className="flex ion-align-items-center flex-direction-vertical">
                    <DAHeading onClick={() => this.props.history.push("/")} 
                        text={getCms("recover-password.title")} 
                        hideHeaderOnMobile />

                    <DACard cardClass="card card-action card-top-fab-btn dots-bg-start-bottom"
                        title={getCms('recover-password.title')}
                        subtitle={getCms('recover-password.instruction')}
                        icon={<KyesIcon />}
                        showTitleOnMobileOnly>

                        <div className="flex ion-align-items-center flex-direction-vertical">
                            <DAInput labelText={getCms('recover-password.password')}
                                    type="password"
                                    value={this.state.password.value}
                                    onChange={(e: any) => this.setState({ password: this.state.password.setValue(e.detail.value!) })}
                                    isFormSubmited={this.state.formSubmitted}
                                    errorMessage={this.state.password.getErrorMessage()} />


                            <div className="line-height-increased text-color-silver mt-reduced mb-increased">
                                {getCms('recover-password.passwordInfo')}
                            </div>

                            <DAInput labelText={getCms('recover-password.passwordRepeat')}
                                type="password"
                                value={this.state.password2.value}
                                onChange={(e: any) => this.setState({ password2: this.state.password2.setValue(e.detail.value!) })}
                                isFormSubmited={this.state.formSubmitted}
                                errorMessage={this.state.password2.getErrorMessage()} />

                            <ValidationMessage message={this.state.recoverErrorMessage} />

                            <DAButton 
                                disabled={this.state.password.value.length === 0 || this.state.password2.value.length === 0} 
                                className="mt-increased mt-great-m"
                                submitOnEnter
                                color="primary" 
                                expand="block"
                                size="large"
                                onClick={async () => await this.recover()}>
                                    {getCms('recover-password.save')}
                            </DAButton>
                        </div>
                    </DACard>
                </div>
            </DAPage>
        );
    }
};

export default withRouter(withIonLifeCycle(RecoverPassword));