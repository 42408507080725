import Axios from 'axios';
import { MetaData } from '../models/MetaData';
import { getToken, logoutUser } from './UserService';

import LoginResponse, { MobileUser } from '../models/backendResponses/LoginResponse'
import RegisterExistingUserResponse from '../models/backendResponses/RegisterExistingUserResponse'
import RegisterNewUserResponse from '../models/backendResponses/RegisterNewUserResponse'
import GetNextReservationResponse from '../models/backendResponses/GetNextReservationResponse'
import GetSlotsResponse from '../models/backendResponses/GetSlotsResponse'
import CreateNewReservationResponse from '../models/backendResponses/CreateNewReservationResponse'
import CancelReservationResponse from '../models/backendResponses/CancelReservationResponse'
import GetSubcenterDetailsResponse from '../models/backendResponses/GetSubcenterDetailsResponse'
import ResetPasswordResponse from '../models/backendResponses/ResetPasswordResponse'
import RecoverPasswordResponse from '../models/backendResponses/RecoverPasswordResponse'
import GetDonorProfileResponse from '../models/backendResponses/GetDonorProfileResponse'
import ChangePasswordResponse from '../models/backendResponses/ChangePasswordResponse'
import UpdateSubcenterResponse from '../models/backendResponses/UpdateSubcenterResponse'
import GetSettingResponse from '../models/backendResponses/GetSettingResponse'
import GetCallingCodesResponse from '../models/backendResponses/GetCallingCodesResponse'
import i18n from './I18n';
import { Token } from '@capacitor/push-notifications';
import GetTranslationsResponse from '../models/backendResponses/GetTranslationsResponse';
import GetAllAssetsResponse from '../models/backendResponses/GetAllAssetsResponse';
import DeleteUserResponse from '../models/backendResponses/DeleteUserResponse';
import GetPlacePhoneResponse from '../models/backendResponses/GetPlacePhoneResponse';

export enum NotifyType {
    Error = "error",
    Warning = "warning",
    Success = "success",
    Info = "info",
}

export default class RestService {

    static init() {
        Axios.defaults.baseURL = process.env.react_app_api_url;
        Axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        Axios.defaults.headers.post['Access-Control-Allow-Origin'] = process.env.react_app_api_url;
    }

    static getBaseUrl(): string | undefined {
        return Axios.defaults.baseURL;
    }

    private static processRestResponse(response: any) {
        let success = true;

        let metaData = response?.data?.MetaData as MetaData;
        if (!metaData) {
            metaData = response?.data as MetaData;
        }

        if (metaData) {
            metaData?.Notifications.forEach(function (item) {
                let notifyType: NotifyType | null = null;

                switch (item.Type) {
                    case 0:
                        notifyType = NotifyType.Error;
                        success = false;
                        break;
                    case 1:
                        notifyType = NotifyType.Success;
                        break;
                    case 2:
                        notifyType = NotifyType.Warning;
                        break;
                    case 3:
                        notifyType = NotifyType.Info;
                        break;
                }

                if (notifyType) {
                    console.info(notifyType, item.Message);
                }
            });
        }

        return success;
    }

    static getHeaders = (): any => {
        let token = getToken();

        return {
            Authorization: token ? `Bearer ${token}` : '',
            ai_tzn: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ai_lang: i18n.language || 'en',
            'Access-Control-Allow-Origin': process.env.react_app_api_url,
            'Content-Type': 'application/json;charset=utf-8'
        };
    }

    static login = (username: string, password: string): Promise<LoginResponse | null> => {
        return new Promise<LoginResponse | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/Login",
                data: {
                    Username: username,
                    Password: password
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as LoginResponse)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static resetPassword = (username: string): Promise<ResetPasswordResponse | null> => {
        return new Promise<ResetPasswordResponse | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/ResetPassword",
                data: {
                    Username: username,
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as ResetPasswordResponse)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static recoverPassword = (token: string, newPassword: string): Promise<RecoverPasswordResponse | null> => {
        return new Promise<RecoverPasswordResponse | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/RecoverPassword",
                data: {
                    Token: token,
                    NewPassword: newPassword,
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as RecoverPasswordResponse)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static changePassword = (oldPassword: string, newPassword: string): Promise<ChangePasswordResponse | null> => {
        return new Promise<ChangePasswordResponse | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/ChangePassword",
                data: {
                    OldPassword: oldPassword,
                    NewPassword: newPassword,
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as ChangePasswordResponse)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static deleteUser = (): Promise<DeleteUserResponse | null> => {
        return new Promise<DeleteUserResponse | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/DeleteUser",
                data: {},
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as DeleteUserResponse)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static updateSubcenter = (subcenterId: Number): Promise<UpdateSubcenterResponse | null> => {
        return new Promise<UpdateSubcenterResponse | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/UpdateSubcenter",
                data: {
                    SubcenterId: subcenterId
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as UpdateSubcenterResponse)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static getUserContext = (): Promise<LoginResponse | null> => {
        return new Promise<LoginResponse | null>((resolve) => {
            Axios({
                method: 'get',
                url: "/GetUserContext",
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as LoginResponse)
            }).catch(error => {
                RestService.processError(error);
                resolve(null);
            });
        });
    }

    static getDonorProfile = (): Promise<GetDonorProfileResponse | null> => {
        return new Promise<GetDonorProfileResponse | null>((resolve) => {
            Axios({
                method: 'get',
                url: "/GetDonorProfile",
                data: null,
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as GetDonorProfileResponse)
            }).catch(error => {
                RestService.processError(error);
                resolve(null);
            });
        });
    }
    // Get notification token
    static getNotificationToken = (mobileUserId: Number): Promise<any | null> => {
        return new Promise<any | null>((resolve, reject) => {
            Axios({
                method: 'get',
                url: "/MobileUserToken",
                data:
                {
                    MobileUserId: mobileUserId
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response);
            }).catch(error => {
                RestService.processError(error);
                reject(null);
            });
        });
    }
    // Save notification token
    static setNotificationToken = (mobileUserId: Number, token: Token): Promise<any | null> => {
        return new Promise<any | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/MobileUserToken",
                data: 
                {
                    MobileUserId: mobileUserId,
                    Token: token.value
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response);
            }).catch(error => {
                RestService.processError(error);
                reject(null);
            });
        });
    }

    static getNextReservation = (): Promise<GetNextReservationResponse | null> => {
        return new Promise<GetNextReservationResponse | null>((resolve, reject) => {
            Axios({
                method: 'get',
                url: "/GetNextReservation",
                data: null,
                headers: RestService.getHeaders()
            }).then(response => {
                let res = response.data as GetNextReservationResponse;
                if (res.NextReservationDate) {
                    res.NextReservationDate = new Date(res.NextReservationDate);
                }
                resolve(res)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static getSlots = (mobileUser: MobileUser | null): Promise<GetSlotsResponse | null> => {
        return new Promise<GetSlotsResponse | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/GetSlots",
                data: {
                    From: null,
                    To: null,
                    SubcenterId: mobileUser?.DefaultSubcenterId,
                    MobileUserId: mobileUser?.Id,
                },
                headers: RestService.getHeaders()
            }).then(response => {
                let res = response.data as GetSlotsResponse;
                res.Slots.forEach(s => {
                    s.TimeFrom = new Date(s.TimeFrom);
                    s.TimeTo = new Date(s.TimeTo);
                });
                resolve(res)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static createReservation = (slotId: Number): Promise<CreateNewReservationResponse> => {
        return new Promise<CreateNewReservationResponse>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/CreateReservation",
                data: {
                    SlotId: slotId,
                    IsUserRequest: false
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as CreateNewReservationResponse);
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static cancelReservation = (slotId: Number): Promise<CancelReservationResponse> => {
        return new Promise<CancelReservationResponse>((resolve, reject) => {
            Axios({
                method: 'delete',
                url: "/CancelReservation",
                data: {
                    Id: slotId
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as CancelReservationResponse);
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static registerExistingUser = (username: string, password: string, donorCode: string, defaultSubcenterId: Number): Promise<RegisterExistingUserResponse | null> => {
        return new Promise<RegisterExistingUserResponse | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/RegisterExistingUser",
                data: {
                    Username: username,
                    Password: password,
                    DonorCode: donorCode,
                    DefaultSubcenterId: defaultSubcenterId
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as RegisterExistingUserResponse)
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    }

    static registerNewUser = (
        username: string, 
        password: string, 
        firstName: string, 
        lastName: string, 
        birthdate: string, 
        countryCallingCodeId: string,
        phone: string,
        email: string, 
        defaultSubcenterId: Number
        ): Promise<RegisterNewUserResponse | null> => {
        return new Promise<RegisterNewUserResponse | null>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/RegisterNewUser",
                data: {
                    Username: username,
                    Password: password,
                    FirstName: firstName,
                    LastName: lastName,
                    BirthDate: birthdate,
                    Phone: phone.replace(/\s/g, ''),
                    CountryCallingCodeId: parseInt(countryCallingCodeId),
                    Email: email,
                    DefaultSubcenterId: defaultSubcenterId
                },
                headers: RestService.getHeaders()
            }).then(response => {
                resolve(response.data as RegisterExistingUserResponse)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static getSubcenterDetails = (): Promise<GetSubcenterDetailsResponse | null> => {
        return new Promise<GetSubcenterDetailsResponse | null>((resolve, reject) => {
            Axios({
                method: 'get',
                url: "/GetSubcenterDetails",
                headers: RestService.getHeaders()
            }).then(response => {
                let res = response.data as GetSubcenterDetailsResponse;
                resolve(res)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }    

    static getSetting = (settingName: string, placeId: number | null) : Promise<GetSettingResponse> => {
        return new Promise<GetSettingResponse>((resolve, reject) => {
            Axios({
                method: 'post',
                url: "/GetSetting",
                data: {
                    SettingName: settingName,
                    placeId: placeId
                }
            }).then(response => {
                let res = response.data as GetSettingResponse;
                resolve(res)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static getTranslations = () : Promise<GetTranslationsResponse> => {
        return new Promise<GetTranslationsResponse>((resolve, reject) => {
            Axios({
                method: 'get',
                url: "/Assets/GetTranslations",
                data: {},
                headers: RestService.getHeaders()
            }).then(response => {
                let res = response.data as GetTranslationsResponse;
                resolve(res)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static getAllAssets = () : Promise<GetAllAssetsResponse> => {
        return new Promise<GetAllAssetsResponse>((resolve, reject) => {
            Axios({
                method: 'get',
                url: "/Assets/GetAllAssets",
                data: {},
                headers: RestService.getHeaders()
            }).then(response => {
                let res = response.data as GetAllAssetsResponse;
                resolve(res)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static getPlacePhone = (id: number) : Promise<GetPlacePhoneResponse> => {
        return new Promise<GetPlacePhoneResponse>((resolve, reject) => {
            Axios({
                method: 'get',
                url: `/Assets/GetPlacePhone?id=${id}`,
                data: {},
                headers: RestService.getHeaders()
            }).then(response => {
                let res = response.data as GetPlacePhoneResponse;
                resolve(res)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }

    static processError(error: any) {
        if (error?.response?.status === 401) {
            logoutUser();
        } else {
            console.error(error);
        }
    }
    // 3551
    static getCountryCallingCodes = (): Promise<GetCallingCodesResponse | null> => {
        return new Promise<GetCallingCodesResponse | null>((resolve, reject) => {
            Axios({
                method: 'get',
                url: "/GetCallingCodesResponse",
                headers: RestService.getHeaders()
            }).then(response => {
                let res = response.data as GetCallingCodesResponse;
                resolve(res)
            }).catch(error => {
                RestService.processError(error);
                reject(error);
            });
        });
    }  
}