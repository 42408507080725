import React from 'react';

interface ValidationErrorProps {
    message: string | null;
}

class ValidationMessage extends React.Component<ValidationErrorProps> {
    render() {
        if (this.props.message && this.props.message.length > 0) {
            return (<div className="validation-message text-color-alert">
               {this.props.message}
            </div>)
        }
        return null;
    }
};

export default ValidationMessage;
