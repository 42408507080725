import React from 'react';
import { IonIcon } from '@ionic/react';

class ArrowBackIcon extends React.Component {
    render() {
        return (
            <IonIcon icon="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M244 400L100 256l144-144M120 256h292' class='ionicon-fill-none'/></svg>" title="Zpět" role="img" className="md hydrated">
                <div className="icon-inner">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon s-ion-icon" viewBox="0 0 512 512">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" className="ionicon-fill-none"></path>
                    </svg>
                </div>
            </IonIcon>
        );
    }
}

export default ArrowBackIcon;