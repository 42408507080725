import React from 'react';
import { IonLoading, withIonLifeCycle } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import NextReservation from '../components/NextReservation';
import GetNextReservationResponse from '../models/backendResponses/GetNextReservationResponse';
import RestService from '../services/RestService';
import { checkLoggedUser } from '../services/UserService';
import DonorCard from '../components/Card/DonorCard';
import CenterLocationCard from '../components/Card/CenterLocationCard';
import ContactCard from '../components/Card/ContactCard';
import { getCms } from '../services/CmsService';
import BottomNavigation from '../components/BottomNavigation';
import FinancialRewardCard from '../components/Card/FinancialRewardCard';
import DAPage from '../components/Core/DAPage';
import DAHeading from '../components/Core/DAHeading';
import DACard from '../components/Core/DACard';

interface DashboardState {
    loading: boolean;
    nextReservation: GetNextReservationResponse | null;
}

interface DashboardProps {

}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
    constructor(props: DashboardProps) {
        super(props);

        this.state = {
            loading: false,
            nextReservation: null,
        };
    }

    async ionViewWillEnter() {
        checkLoggedUser();
        await this.loadNextReservation();
    }

    async loadNextReservation() {
        this.setState({ loading: true, nextReservation: null });

        let nextReservation = await RestService.getNextReservation();

        this.setState({
            loading: false,
            nextReservation: nextReservation
        });
    }

    async reservationCancelled() {
        this.setState({ nextReservation: null })
        await this.loadNextReservation();
    }

    render() {
        return (
            <DAPage containerClass={'page-dashboard page-with-navigation'}>
                <IonLoading isOpen={this.state.loading} />

                <div className="flex flex-direction-vertical">
                    <DAHeading text={getCms("dashboard.subtitle")} showHeadlineOnMobile />
                    
                    <div className="flex flex-direction-vertical gap-default">
                        <DACard cardClass="dots-bg-end-top page-dashboard__reservation-container" smallCard>
                            <NextReservation nextReservation={this.state.nextReservation} cancelCalback={() => this.reservationCancelled()} />
                        </DACard>
                        <div className='page-dashboard__info-grid'>
                            <div className="flex flex-direction-vertical gap-default">
                                <CenterLocationCard />
                                <ContactCard />
                            </div>
                            <div className="flex flex-direction-vertical gap-default">
                                <DonorCard />
                                <FinancialRewardCard />
                            </div>
                        </div>
                    </div>
                </div>
                <BottomNavigation selected="dashboard" />
            </DAPage>
        );
    }
};

export default withRouter(withIonLifeCycle(Dashboard));