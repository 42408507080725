import React from 'react';

class LocationDetailIcon extends React.Component {
    render() {
        return (
            <svg width="129" height="128" viewBox="0 0 129 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="64.375" cy="64" r="64" fill="#F5F5FA"/>
                <path d="M35.875 121.5C34.659 120.904 27.7698 116.917 26.875 116C26.875 116 24.5975 114.341 20.875 111L25.5574 101.416C25.7466 102.243 25.9187 103.064 26.1309 103.885C26.2227 104.256 26.4407 104.565 26.8881 104.526C27.2035 104.498 27.5821 104.29 27.5419 103.997C27.3469 102.468 27.8287 100.741 26.2858 99.5769C28.4195 93.52 29.6298 87.2045 31.9757 81.1871C32.4782 79.6777 33.1334 78.2213 33.9316 76.8398C35.8244 73.9098 37.4132 73.3924 40.6883 74.6241C41.325 74.8659 41.9731 75.0796 42.6155 75.3046C42.7614 75.7668 42.8745 76.2385 42.954 76.7161C43.2006 79.4437 43.9233 79.5786 40.4761 79.3537C40.023 79.3537 39.3289 79.1287 39.2601 79.798C39.2028 80.3603 39.8337 80.5066 40.3327 80.5909C43.9405 81.1533 47.5311 80.8271 51.1102 80.3266C51.8329 80.2254 52.9571 80.1973 52.8309 79.3537C52.7047 78.5101 51.6838 78.257 50.7202 78.4595C50.4162 78.5214 50.1179 78.6113 49.8082 78.6676C47.3762 79.095 47.3762 79.095 47.382 76.5586C49.2454 76.1697 51.1734 76.1887 53.0285 76.6145C54.8835 77.0403 56.6199 77.8623 58.1136 79.0219C60.4079 80.709 60.4882 82.2668 58.1939 83.9708C56.7657 85.0281 56.4387 85.7367 57.5228 87.2776C58.8535 89.156 58.1767 90.9106 55.8021 91.951C54.2362 92.6371 53.2267 93.0758 54.5345 94.9935C55.412 96.2757 54.804 97.6029 53.2324 98.1765C52.1254 98.5814 51.7526 98.9357 52.5384 100.1C53.502 101.534 53.112 102.591 51.2249 103.412C48.3284 104.644 45.2797 105.499 42.1567 105.954L40.1434 106.286C38.6751 105.499 37.2354 106.145 35.8014 106.393C35.4057 106.46 34.8034 106.623 34.9124 107.147C35.0214 107.67 35.6581 107.709 36.1456 107.664C37.2469 107.518 38.3424 107.248 39.4666 107.49C38.0728 111.848 37.287 116.353 36.1112 120.762C35.8818 121.521 36.3797 120.836 35.875 121.5Z" fill="#EAAC86"/>
                <path d="M47.5101 76.6409C47.5101 79.1883 47.5101 79.1883 49.8809 78.759C50.1836 78.7025 50.475 78.6121 50.7721 78.55C51.6913 78.3467 52.7225 78.5218 52.8346 79.4481C52.9467 80.3745 51.837 80.3236 51.1532 80.4253C47.6558 80.928 44.1473 81.2839 40.6219 80.6908C40.1231 80.6061 39.5009 80.4705 39.5738 79.8943C39.641 79.2222 40.3192 79.4199 40.762 79.4481C44.1248 79.6741 43.4242 79.5385 43.1832 76.799C43.1056 76.3193 42.9951 75.8456 42.8526 75.3812C42.7293 73.7922 42.6041 72.2049 42.477 70.6196C42.4378 70.1 42.3089 69.6594 41.67 69.5973C37.1525 69.1849 34.6304 66.0218 32.7976 62.4464C27.4339 51.9346 26.3353 40.9823 30.0849 29.6459C30.7568 27.6526 31.9128 25.8603 33.4478 24.4324C37.685 20.4277 45.7782 21.5178 49.3541 26.6014C55.0261 34.6617 56.3936 43.7896 55.7155 53.3863C55.5145 57.0777 54.5423 60.6847 52.8626 63.9714C51.7417 66.1235 50.234 67.9874 47.8184 68.8121C46.972 69.1002 46.647 69.5577 46.7647 70.4389C47.0113 72.5005 47.2523 74.5735 47.5101 76.6409ZM51.1158 57.0748C51.1158 57.0748 51.2744 55.2967 51.3606 54.8775C52.2685 45.6762 51.0915 36.8759 46.3723 28.7761C45.1219 26.505 43.271 24.6279 41.0254 23.3536C39.9044 22.7322 38.9909 22.7492 37.9428 23.7772C35.5173 26.2273 33.8806 29.357 33.246 32.7582C31.1951 41.8785 32.1555 51.4243 35.9811 59.9441C37.4103 63.175 39.344 66.0331 42.421 68.0101C43.8782 68.9533 45.1057 69.0324 46.221 67.5638C47.7291 66.0564 48.9138 64.2527 49.7015 62.2647C50.4892 60.2767 51.1158 57.4792 51.1158 57.0748Z" fill="#1E1E1F"/>
                <path d="M39.1283 107.774C38.0605 107.503 37.0622 107.774 36.0371 107.97C35.5833 108.039 35.0014 108.039 34.8893 107.39C34.7772 106.74 35.3485 106.62 35.7168 106.544C37.0515 106.267 38.3862 105.541 39.7583 106.424C40.1053 107.232 39.6141 107.503 39.1283 107.774Z" fill="#1E1E1F"/>
                <path d="M25.608 99C27.1606 100.175 26.6758 101.918 26.872 103.463C26.9124 103.758 26.5315 103.968 26.2141 103.997C25.7639 104.036 25.5445 103.724 25.4522 103.349C25.2502 102.52 25.077 101.691 24.875 100.857C25.1116 100.232 24.6615 99.352 25.608 99Z" fill="#1E1E1F"/>
                <path d="M46.7078 66.9004C45.5658 68.3442 43.7797 68.241 42.2952 67.3136C39.189 65.37 37.1906 62.5602 35.7346 59.3838C31.8372 51.0077 30.8589 41.6231 32.9482 32.6567C33.5947 29.3128 35.2621 26.236 37.733 23.8273C38.8008 22.8166 39.72 22.7999 40.8734 23.4108C43.1611 24.6636 45.0468 26.509 46.3206 28.7417C51.1283 36.7049 52.5324 44.7337 51.6074 53.7797C51.5195 54.1919 51.1991 56.5629 51.1991 56.5629L51.1102 57.2548C50.8982 58.9065 50.4521 60.5464 49.8164 62.0855C49.3645 63.1798 48.7882 64.1991 48.0658 65.1371L46.7078 66.9004ZM47.5083 42.7467C47.5355 40.6225 46.8186 38.5525 45.4756 36.8771C45.2353 36.5594 44.9125 36.3097 44.5402 36.1536C44.1679 35.9974 43.7596 35.9405 43.3572 35.9886C43.0447 36.0217 42.7514 36.152 42.521 36.36C42.2906 36.5681 42.1353 36.8429 42.0782 37.1436C41.6945 38.4491 41.6388 39.8251 41.9161 41.1558C42.1933 42.4866 42.7953 43.733 43.6713 44.7902C44.2023 45.451 44.9674 46.2396 46.018 45.9342C47.0686 45.6287 47.4169 44.6681 47.5026 43.6518C47.5311 43.352 47.5083 43.0466 47.5083 42.7467ZM43.3115 32.0514C43.0146 30.9408 42.495 30.1134 41.2046 30.1578C40.9729 30.1589 40.7439 30.2072 40.5326 30.2997C40.3213 30.3922 40.1323 30.5268 39.9778 30.6948C39.8234 30.8628 39.7069 31.0605 39.6359 31.275C39.565 31.4896 39.5411 31.7163 39.5659 31.9403C39.5497 32.1972 39.5902 32.4545 39.6847 32.6949C39.7791 32.9353 39.9254 33.1532 40.1136 33.3341C40.3017 33.5151 40.5275 33.6547 40.7758 33.7438C41.024 33.8329 41.289 33.8693 41.5529 33.8506C42.535 33.8173 43.0489 32.951 43.3115 32.0514Z" fill="#BAD9D4"/>
                <path d="M63.875 23L89.375 22V77.5L64.875 71.5L63.875 23Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M76.3923 31.5C73.8988 31.5 71.875 33.457 71.875 35.8683C71.875 38.3582 74.5131 42.1673 76.3923 44.5C78.2986 42.1499 80.9096 38.3844 80.9096 35.8683C80.9096 33.457 78.8859 31.5 76.3923 31.5ZM76.3913 38.0523C75.1445 38.0523 74.1327 37.0738 74.1327 35.8681C74.1327 34.6625 75.1445 33.684 76.3913 33.684C77.6381 33.684 78.65 34.6625 78.65 35.8681C78.65 37.0738 77.6381 38.0523 76.3913 38.0523Z" fill="#FA6966"/>
            </svg>
        );
    }
}

export default LocationDetailIcon;