import React from 'react';
import { SubcenterDetail } from '../models/backendResponses/GetSubcenterDetailsResponse'
import { getCms } from '../services/CmsService';

interface GdprLinkProps {
    subcenter: SubcenterDetail | null;    
}

class GdprLink extends React.Component<GdprLinkProps, {}> {
    cssClasses = `font-size-default line-height-default ${this.props.subcenter?.GdprUrl ? 'text-color-green-bottom-menu' : ''}`.trim();

    render() {
         if (this.props.subcenter?.GdprUrl) {
             return (<a target="_blank" rel="noreferrer" href={this.props.subcenter?.GdprUrl} className={this.cssClasses}>
                    {getCms('registration.gdprAgreement')}
                </a>);
         } else {
            return (<span className={this.cssClasses}>{getCms('registration.gdprAgreement')}</span>);
         }
    }
};

export default GdprLink;
