import React from 'react';

class InfoIcon extends React.Component {
    render() {
        return (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.60928 5.621H6.08244V6.52764H6.39016V9.77956H6.08244V10.6862H7.91784V9.77956H7.60928V5.621ZM14 7C14 3.13404 10.866 0 7 0C3.13404 0 0 3.13404 0 7C0 10.866 3.13404 14 7 14C10.866 14 14 10.866 14 7ZM7 0.7924C10.4289 0.7924 13.2076 3.57112 13.2076 7C13.2076 10.4289 10.4289 13.2076 7 13.2076C3.57112 13.2076 0.7924 10.4289 0.7924 7C0.7924 3.57112 3.57112 0.7924 7 0.7924ZM6.29272 4.02164C6.29272 4.41196 6.6094 4.72836 7 4.72836C7.39004 4.72836 7.70672 4.41196 7.70672 4.02164C7.70672 3.63104 7.39004 3.31436 7 3.31436C6.6094 3.31408 6.29272 3.63076 6.29272 4.02164Z" fill="#0C9682"/>
            </svg>
        );
    }
}

export default InfoIcon;