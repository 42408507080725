import React from 'react';
import { IonLoading, withIonLifeCycle } from '@ionic/react';
import RestService from '../services/RestService';
import { getFirstNotificationMessage } from '../models/MetaData';
import { setLoggedUser } from '../services/UserService';
import { withRouter, Redirect } from "react-router-dom";
import LoginResponse from '../models/backendResponses/LoginResponse'
import FinancialReward from '../components/FinancialReward';
import { BaseState } from '../models/BaseState';
import { getCms } from '../services/CmsService';
import DAPage from '../components/Core/DAPage';
import LoginForm from '../components/LoginForm';
import { LoginCredential } from '../models/LoginCredential';

interface LoginProps {

}

interface LoginState extends BaseState {
    loading: boolean
    loginErrorMessage: string | null
}

class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);

        this.state = {
            loading: false,
            loginErrorMessage: null
        };
    }

    ionViewWillEnter() {
        this.setState({ loginErrorMessage: null });
    }

    async login(username:string, password:string) {

        this.setState({ loading: true });

        let res: LoginResponse | null;

        try {
            res = await RestService.login(username, password);
        }
        
        catch (error) {
            console.error(error);
            res = null;
        }
            
        if (res?.Token) {
            this.setState({ 
                loading: false,
                loginErrorMessage: null,
            });

            await setLoggedUser(res, new LoginCredential(username, password));
            window.location.replace("/Dashboard");
        } else {
            this.setState({ 
                loading: false,
                loginErrorMessage: getFirstNotificationMessage(res?.MetaData) ?? "Login failed"
            });
        }
    }

    register(e:Event) {
        e.preventDefault(); 
        this.setState({redirectTo: "/Registration"});
    }

    render() {
        if(this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }

        return (
            <DAPage containerClass={'ion-content--before-login page-login'}>
                <IonLoading isOpen={this.state.loading} />

                <div className="page-login__content fill-content-page ion-no-padding">
                    <div>
                        <div className="page-login__content--rewards flex flex-direction-vertical ion-justify-content-center ion-no-padding">
                            <div className="ion-text-center ion-text-lg-left">
                                <h1>{getCms('login.hero.title')}</h1>
                                {/* <p>{getCms('login.hero.slogan')}</p> */}
                            </div>

                            <div className="ion-hide-xl-down ion-align-items-center">
                                <div className="card top-without-radius p-great primary-top-line dots-bg-end-top dots-bg-start-bottom">
                                    <FinancialReward />
                                </div>
                            </div>
                        </div>

                        <div className="page-login__content--loginForm ion-text-center ion-align-items-center ion-no-padding">
                            <LoginForm onSubmit={this.login.bind(this)}
                                onRegister={this.register.bind(this)}
                                loginErrorMessage={this.state.loginErrorMessage} />
                        </div>

                        <div className="ion-text-center ion-padding-top mb-great ion-hide-xl-up">
                            <FinancialReward />
                        </div>
                    </div>
                </div>
            </DAPage>
        );
    }
};

export default withRouter(withIonLifeCycle(Login));
