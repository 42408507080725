import React from 'react';
import { IonAlert, AlertButton } from '@ionic/react';
import { getCms } from '../../services/CmsService';

interface DAPopupProps {
    onDismiss: Function;
    open: boolean;
    header: string;
    message: string;
    buttons?: Array<AlertButton>;
    cssClass?: string;
}

class DAPopup extends React.Component<DAPopupProps> {
    
    defaultConfirmBtn():[AlertButton] {
        return [{
            text: getCms('common.confirm'),
            cssClass: this.props.cssClass ?? ''
        }]
    }

    render() {
        return (
            <IonAlert isOpen={this.props.open}
                cssClass={`da-alert ${this.props.cssClass ?? ''}`}
                header={this.props.header}
                message={this.props.message}
                buttons={this.props.buttons?.length ? this.props.buttons : this.defaultConfirmBtn()}
                onDidDismiss={() => this.props.onDismiss()} />
        );
    }
};

export default DAPopup;