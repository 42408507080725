import React from 'react';
import { IonCard, IonIcon, withIonLifeCycle, IonCardHeader, IonCardTitle, IonCardSubtitle } from '@ionic/react';
import { withRouter } from 'react-router-dom';

interface DACardProps {
    icon?: string | React.Component;
    title: string;
    subtitle?: string;
    cardClass?: string;
    contentClass?: string;
    smallCard?: boolean;
    showTitleOnMobileOnly?: boolean;
}

class DACard extends React.Component<DACardProps> {
    renderHeader() {
        if(this.props.smallCard) {
            return this.renderInlineTitle();
        } else {
            return this.renderDefaultTitle();
        }
    }

    renderDefaultTitle() {
        if(this.props.title || this.props.subtitle) {
            return (
                <IonCardHeader>
                    { this.props.title ? <IonCardTitle className={this.props.showTitleOnMobileOnly ? 'ion-hide-md-up' : ''}>{this.props.title}</IonCardTitle> : "" }
                    { this.renderHeaderIcon() }
                    { this.props.subtitle ? <IonCardSubtitle>{this.props.subtitle}</IonCardSubtitle> : "" }
                </IonCardHeader>
            )
        }
    }

    renderInlineTitle() {
        if(this.props.icon || this.props.title) {
            return (
                <div className='card__header'>
                    { this.renderHeaderIcon() }
                    { this.props.title ? <span>{this.props.title}</span> : "" }
                </div>
            )
        }
    }

    renderHeaderIcon() {
        if(this.props.icon) {
            if (typeof this.props.icon === 'string') {
                return <IonIcon icon={this.props.icon} className="text-color-secondary-main"></IonIcon>
            } else if (React.isValidElement(this.props.icon)) {
                return this.props.icon;
            }
        }
    }

    render() {
        return (
            <IonCard className={`card ${this.props.smallCard && 'card-small'} ${this.props.cardClass ?? ''}`.trim()}>
                
                { this.renderHeader() }

                <div className={`card__content ${this.props.contentClass ?? ''}`.trim()}>
                    { this.props.children }
                </div>
            </IonCard>
        );
    }
};

export default withRouter(withIonLifeCycle(DACard));