import React from 'react';
import { IonPopover} from '@ionic/react';

interface DAPopoverProps {
    onDismiss: Function;
    visible: boolean;
    event?: Event;
    cssClass?: string;
}

class DAPopover extends React.Component<DAPopoverProps> {
    render() {
        return (
            <IonPopover event={this.props.event ?? undefined}
                className={`da-popover ${this.props.cssClass ?? ''}`}
                showBackdrop={false}
                isOpen={this.props.visible}
                onDidDismiss={() => this.props.onDismiss()}>
                {this.props.children}
            </IonPopover>
        );
    }
};

export default DAPopover;