export default class RegexHelper {
    static Phone() {
        return /^(?=^.{6,15}$)(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\s.]|\d)+$/;
    }

    static Email() {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }

    static isValidPhone(value: string) {
        return this.Phone().test(String(value).toLowerCase());
    }
    
    static isValidEmail(value: string) {
        return this.Email().test(String(value).toLowerCase());
    }
}