import React from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { withRouter } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

interface DATooltipProps {
    id?: string;
    showOnHover?: boolean;
    cssClass?: string;
    message?: string;
}

class DATooltip extends React.Component<DATooltipProps> {
    tooltipRef: React.RefObject<any>;
    autoShowEvent: string;
    autoHideEvent: string;
    showHideDelay: number;
    cssClass: string;

    constructor(props: DATooltipProps) {
        super(props);

        this.tooltipRef = React.createRef();
        // If showOnHover prop is set, assign events & delay
        this.autoShowEvent = this.props.showOnHover ? 'mouseover' : 'none';
        this.autoHideEvent = this.props.showOnHover ? 'mouseleave' : 'none';
        this.showHideDelay = this.props.showOnHover ? 150 : 0;
        this.cssClass = this.props.cssClass || '';
    }

    render() {
        return (
            <ReactTooltip
                    ref={this.tooltipRef}
                    id={this.props.id}
                    className={`da-tooltip ${this.cssClass}`}
                    place='top'
                    effect='solid'
                    event={this.autoShowEvent}
                    eventOff={this.autoHideEvent}
                    delayShow={this.showHideDelay}
                    delayHide={this.showHideDelay}
                    globalEventOff={'ionScroll'}
                    multiline
                    scrollHide
                    resizeHide
                    afterShow={e => {
                        let tooltipRef = this.tooltipRef?.current?.tooltipRef;
                        
                        if (!tooltipRef) return;
                    
                        let rect = tooltipRef.getBoundingClientRect();
                        let overflownLeft = rect.left < 0;
                        let overflownRight = rect.right > window.innerWidth;
                    
                        if (overflownLeft) {
                          tooltipRef.style.setProperty('left', '10px');
                          tooltipRef.style.setProperty('right', 'auto');
                        } else if (overflownRight) {
                          tooltipRef.style.setProperty('left', 'auto');
                          tooltipRef.style.setProperty('right', '10px');
                        }
                      }}>
                        {this.props.message}
                        {this.props.children}
            </ReactTooltip>
        );
    }
};

export default withRouter(withIonLifeCycle(DATooltip));
