import React from 'react';
import { withIonLifeCycle } from '@ionic/react';
import DABackArrowBtn from './DABackArrowBtn';


interface DAHeadingProps {
    text: string
    margin?: 'large'
    onClick?: Function
    showHeadlineOnMobile?: boolean
}

class DAHeading extends React.Component<DAHeadingProps> {
    render() {
        return (
            <div className={`da-heading ${this.props.margin ? `da-heading--${this.props.margin}` : ''}`.trim()}>
                {this.props.onClick &&
                    <DABackArrowBtn onClick={(e:any) => {if (this.props.onClick) this.props.onClick(e) }} />
                }
                
                <h1 className={`text-color-black ${this.props.showHeadlineOnMobile ? '' : 'ion-hide-md-down'}`.trim()}>{this.props.text}</h1>
            </div>
        );
    }
};

export default withIonLifeCycle(DAHeading);