import React from 'react';

class PhoneIcon extends React.Component {
    render() {
        return (
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.80384 6.99134C3.75557 4.77237 4.04812 4.45544 4.04812 4.45544L4.92619 3.50465C5.24936 3.15454 5.24936 2.58658 4.92619 2.23692L3.1055 0.264062C2.79722 -0.0694558 2.30233 -0.090973 1.97626 0.221924C1.43957 0.736544 0.715844 1.58603 0.537085 2.55385C0.244533 4.13851 1.70771 6.35748 4.04812 8.89337C6.38895 11.4288 8.43722 13.0139 9.89998 12.697C10.7934 12.5033 11.5775 11.7193 12.0525 11.1379C12.3414 10.7846 12.3215 10.2485 12.0136 9.91455L10.1925 7.94213C9.86977 7.59203 9.3455 7.59203 9.02233 7.94213L8.14467 8.89337C8.14467 8.89337 7.85212 9.2103 5.80384 6.99134Z" fill="#BEBEBE" />
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="13">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.80384 6.99134C3.75557 4.77237 4.04812 4.45544 4.04812 4.45544L4.92619 3.50465C5.24936 3.15454 5.24936 2.58658 4.92619 2.23692L3.1055 0.264062C2.79722 -0.0694558 2.30233 -0.090973 1.97626 0.221924C1.43957 0.736544 0.715844 1.58603 0.537085 2.55385C0.244533 4.13851 1.70771 6.35748 4.04812 8.89337C6.38895 11.4288 8.43722 13.0139 9.89998 12.697C10.7934 12.5033 11.5775 11.7193 12.0525 11.1379C12.3414 10.7846 12.3215 10.2485 12.0136 9.91455L10.1925 7.94213C9.86977 7.59203 9.3455 7.59203 9.02233 7.94213L8.14467 8.89337C8.14467 8.89337 7.85212 9.2103 5.80384 6.99134Z" fill="white" />
                </mask>
            </svg>
        );
    }
}

export default PhoneIcon;