import { Notification } from '../models/Notification';

export interface MetaData {
    AffectedEntityId: number,
    AffectedEntityVestion: string,
    TimezoneName: string,
    Lcid: string,
    RedirectUrl: string,
    CurrentSubCenterIds: [],
    InvalidProperties: [],
    Notifications: Array<Notification>
}

export function getFirstNotificationMessage(metaData: MetaData | null | undefined): string | null {
    if (metaData && metaData.Notifications?.length) {
        return metaData.Notifications[0].Message;
    } else {
        return null;
    }
}

export function isSuccess(metaData: MetaData | null | undefined): boolean {
    if (metaData === null || metaData === undefined) {
        return false;
    }

    if (metaData.Notifications.find((n) => n.Type === 0)) {
        return false;
    } else {
        return true;
    }
}