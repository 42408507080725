import React from 'react';

class DashboardIcon extends React.Component<any> {
    render() {
        return (
            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.53526 0C3.2038 0 0.5 2.4086 0.5 5.37634C0.5 8.44086 4.02459 13.129 6.53526 16C9.08214 13.1075 12.5705 8.47312 12.5705 5.37634C12.5705 2.4086 9.86673 0 6.53526 0ZM6.53423 8.06384C4.8685 8.06384 3.5166 6.85954 3.5166 5.37567C3.5166 3.8918 4.8685 2.6875 6.53423 2.6875C8.19997 2.6875 9.55186 3.8918 9.55186 5.37567C9.55186 6.85954 8.19997 8.06384 6.53423 8.06384Z" fill="#FA6966" />
            </svg>
        );
    }
}

export default DashboardIcon;