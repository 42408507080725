import React from 'react';

class DASeparator extends React.Component {
    render() {
        return (
            <div className='da-separator'></div>
        );
    }
};

export default DASeparator;