import React from 'react';
import { IonLoading, withIonLifeCycle } from '@ionic/react';

interface DASuspenseProps {
    isShown?: boolean;
}

class DASuspense extends React.Component<DASuspenseProps> {
    render() {
        return (
            <IonLoading
                cssClass='da-loading-page'
                isOpen={this.props.isShown ?? true}
                spinner='lines'
            />
        );
    }
};

export default withIonLifeCycle(DASuspense);