import React from 'react';

class LocationIcon extends React.Component {
    render() {
        return (
            <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.5173 0C2.02375 0 0 1.95699 0 4.36828C0 6.8582 2.6381 10.6673 4.5173 13C6.4236 10.6499 9.0346 6.88441 9.0346 4.36828C9.0346 1.95699 7.01085 0 4.5173 0ZM4.51632 6.55227C3.26954 6.55227 2.25766 5.57377 2.25766 4.36813C2.25766 3.16248 3.26954 2.18399 4.51632 2.18399C5.76309 2.18399 6.77497 3.16248 6.77497 4.36813C6.77497 5.57377 5.76309 6.55227 4.51632 6.55227Z" fill="#BEBEBE" />
            </svg>
        );
    }
}

export default LocationIcon;