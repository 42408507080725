import React from "react";
import { IonContent, IonPage, withIonLifeCycle } from "@ionic/react";

import Header from "../Header";
import { isLogged } from "../../services/UserService";
import { Notifications } from "../Notifications/component";

interface DAPageProps {
    containerClass?: string
    contentClass?: string
    contentScrollEvents?: boolean
    hideHeaderOnMobile?: boolean
}

class DAPage extends React.Component<DAPageProps> {
    
    render(): React.ReactNode {
        return (
            <IonPage>
                <IonContent fullscreen={false}
                    className={`${isLogged() ? 'content-loggedIn' : ''} ${this.props.containerClass ?? ''} ${this.props.hideHeaderOnMobile ? 'content-hideHeaderOnMobile' : ''}`.trim()} 
                    scrollEvents={this.props.contentScrollEvents ?? false}>
                
                    <Notifications />

                    <Header hideOnMobile={this.props.hideHeaderOnMobile ?? false} />

                    <div className={`page-content ${this.props.contentClass ?? ''}`.trim()}>
                        {this.props.children}
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(DAPage);