import React from 'react';

class DashboardIcon extends React.Component {
    render() {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='home-icon'>
                <path d="M0.65 5.21339C0.65 5.08225 0.723308 4.96212 0.839928 4.90214L8.83993 0.787854C8.94039 0.736189 9.05961 0.736188 9.16007 0.787854L17.1601 4.90214C17.2767 4.96212 17.35 5.08225 17.35 5.21339V16.9746C17.35 17.1679 17.1933 17.3246 17 17.3246H1C0.806701 17.3246 0.65 17.1679 0.65 16.9746V5.21339Z" stroke="#0C9682" strokeWidth="1.3" />
            </svg>
        );
    }
}

export default DashboardIcon;