import React from 'react';
import { IonLoading, withIonLifeCycle, IonRow, IonCol, IonLabel, IonSegment, IonSegmentButton, IonGrid } from '@ionic/react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import qs from "qs";

import RegisterExisting from '../components/RegisterExisting';
import RegisterNew from '../components/RegisterNew';
import RestService from '../services/RestService';
import GetSubcenterDetailsResponse, { SubcenterDetail } from '../models/backendResponses/GetSubcenterDetailsResponse';
import { getCms } from '../services/CmsService';
import DAPage from '../components/Core/DAPage';

interface RegistrationProps extends RouteComponentProps {

}

export enum RegistrationTab {
    Registered = "Registered",
    New = "New"
}

// Url params RegType possible values, examle: /Registration?regType=alreadyvisited 
export enum RegTypeUrlParam {
    Registered = "alreadyvisited",
    New = "firsttime"
}

interface RegistrationState {
    segmentValue: RegistrationTab.New | RegistrationTab.Registered | string | undefined;
    loading: boolean;
    subcenters: Array<SubcenterDetail> | null;
}

class Registration extends React.Component<RegistrationProps, RegistrationState> {
    constructor(props: RegistrationProps) {
        super(props);
        let regType = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).regType;
        
        this.state = {
            segmentValue: regType === RegTypeUrlParam.New ? RegistrationTab.New : RegistrationTab.Registered,
            loading: true,
            subcenters: null
        };
    }

    async ionViewWillEnter() {
        let subcentersResponse: GetSubcenterDetailsResponse | null = null;
        
        try {
            subcentersResponse = await RestService.getSubcenterDetails();
        }
        catch (error) {
            console.error(error);
        }

        this.setState({
            loading: false,
            subcenters: subcentersResponse?.Subcenters ?? null
        });
    }

    render() {
        return (
            <DAPage contentScrollEvents containerClass={'page-registration'}>
                <IonLoading isOpen={this.state.loading} />

                <IonGrid className='sp-none ion-no-padding'>
                    <IonRow className="ion-text-left">
                        <h1 className="heading-font-xl margin-l text-color-black">{getCms("registration.title")}</h1>
                    </IonRow>
                    <IonRow className='card py-big'>
                        <IonRow>
                            <IonCol sizeXs="12">
                                <IonSegment color="primary" className="flex" mode="md" value={this.state.segmentValue} onIonChange={e => this.setState({segmentValue: e.detail.value as string})}>
                                    <IonSegmentButton value={RegistrationTab.Registered}>
                                        <IonLabel>{getCms('registration.registerExistingOption')}</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value={RegistrationTab.New}>
                                        <IonLabel>{getCms('registration.registerNewOption')}</IonLabel>
                                    </IonSegmentButton>
                                </IonSegment>

                                <p className='font-size-heading-3 text-color-grey-dim ion-no-margin py-big py-great-xl'>{getCms('registration.subtitle')}</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                {this.renderInnerContent()}
                            </IonCol>
                        </IonRow>
                    </IonRow>
                </IonGrid>
            </DAPage>
        );
    }

    renderInnerContent = () => {
        if(!this.state.loading) {
            return (<React.Fragment>
                { this.state.segmentValue === RegistrationTab.Registered
                ? <RegisterExisting subcenters={this.state.subcenters} /> 
                : <RegisterNew subcenters={this.state.subcenters} /> }
            </React.Fragment>)
        }
    }
};

export default withRouter(withIonLifeCycle(Registration));
