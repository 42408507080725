import { IonButton, IonLabel } from '@ionic/react';

import nextId from "react-id-generator";
import React from 'react';
import InfoIcon from '../Svg/InfoIcon';
import DATooltip from './DATooltip';
import ReactTooltip from 'react-tooltip';

interface DAInputLabelProps {
    labelText?: string;

    tooltipIcon?: string;
    tooltipContent?: Array<string> | string;
}

interface DAInputLabelState {

    tooltipVisible: boolean;
}

class DAInputLabel extends React.Component<DAInputLabelProps, DAInputLabelState> {
    
    tooltipRef: any;
    tooltipId: string;


    constructor(props: DAInputLabelProps) {
        super(props);

        this.tooltipRef = React.createRef();
        this.tooltipId = nextId('tooltipId-');

        this.state = {
            tooltipVisible: false
        }
    }
    

    render() {
        if(this.props.labelText && this.props.labelText.length > 0) {
            return (
                <IonLabel className="da-input-label" position="stacked">
                        <div className="ion-padding-bottom">{this.props.labelText} 
                            {this.renderTooltip()}
                        </div>
                </IonLabel>
            );
        }
        return null;
    }

    renderTooltip = () => {
        if(this.props.tooltipContent?.length) {
            return <React.Fragment>
                        <IonButton ref={ref => this.tooltipRef = ref}  
                            className="da-label-tooltip"
                            data-tip
                            data-for={this.tooltipId}
                            color="primary" 
                            fill="clear" 
                            expand="block"
                            onClick={(e: any) => this.tooltipIconClick(e)}>
                            {this.renderTooltipIcon()}
                        </IonButton>

                        <DATooltip id={this.tooltipId}>
                           {this.renderTooltipContent()}
                        </DATooltip>
            </React.Fragment>
        }
    }

    renderTooltipIcon = () => {
        switch(this.props.tooltipIcon) {
            default:
                return <InfoIcon />;
        }
    }

    renderTooltipContent = () => {
        if (Array.isArray(this.props.tooltipContent)) {
            return this.props.tooltipContent.map((msg, i, arr) => (
                <div className={`da-tooltip-row ${arr.length > 1 ? 'da-tooltip-row-list' : ''}`} key={i}>
                    {msg}
                </div>
            ));
        } else {
            return <div className='da-tooltip-row'>{this.props.tooltipContent}</div>
        }
    }
    
    tooltipIconClick = (e: any) => {
        if (!this.state.tooltipVisible) {
            this.setState({ tooltipVisible: true });
            ReactTooltip.show(this.tooltipRef);
        } else {
            this.setState({ tooltipVisible: false });
            ReactTooltip.hide(this.tooltipRef);
        }
    }
}

export default DAInputLabel;