import React from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import DACard from '../Core/DACard';
import RestService from '../../services/RestService';
import FinancialReward from '../FinancialReward';

interface FinancialRewardCardProps {

}

interface FinancialRewardCardState {
    barcode: string | undefined
}

class FinancialRewardCard extends React.Component<FinancialRewardCardProps, FinancialRewardCardState> {
    constructor(props: FinancialRewardCardProps) {
        super(props);

        this.state = {
            barcode: undefined
        };
    }

    async ionViewWillEnter() {
        let donorProfile = await RestService.getDonorProfile();
        this.setState({ barcode: donorProfile?.Code })
    }

    render() {
        return (
            <DACard cardClass="card-rewards dots-bg-end-bottom" contentClass="ion-text-center" smallCard>
                <FinancialReward />
            </DACard>
        );
    }
};

export default withRouter(withIonLifeCycle(FinancialRewardCard));