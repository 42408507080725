import React from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { withRouter } from "react-router-dom";
import { SubcenterDetail } from '../models/backendResponses/GetSubcenterDetailsResponse'
import DASelect from './Core/DASelect';
import { getCms } from '../services/CmsService';

interface SubcenterChooserProps {
    formSubmitted: boolean;    
    subcenters: Array<SubcenterDetail> | null;
    subcenterSelected: (subcenter: SubcenterDetail | null) => void;
    selectedCenter: SubcenterDetail | null
}

class SubcenterChooser extends React.Component<SubcenterChooserProps> {
    constructor(props: SubcenterChooserProps) {
        super(props);

        if (this.props.subcenters?.length === 1) {
            this.props.subcenterSelected(this.props.subcenters[0]);
        }
    }

    getSelectedSubcenter(): SubcenterDetail | null {
        if (this.props.subcenters && this.props.subcenters.length > 0) {
            if (this.props.selectedCenter) return this.props.selectedCenter;


            if (this.props.subcenters.length === 1) {
                return this.props.subcenters[0];
            }
        }

        return null;
    }

    getFullAddress(sd: SubcenterDetail): string {
        return `${sd.AddressStreet}, ${sd.AddressCity}, ${sd.AddressPostalCode}`;
    }

    getSubcenterErrorMessage(): string | null {
        if (this.getSelectedSubcenter() === null) {
            return getCms('subcenter-chooser.validationMessages.enterSubcenter');
        }

        return null;
    }

    render() {
        if (!this.props.subcenters || this.props.subcenters.length < 2) {
            return (null);
        }

        return (
            <div className="subcenter-chooser">
                <div>
                    <h2 className="ion-text-left text-color-black mb-regular">{getCms('subcenter-chooser.title')}</h2>
                </div>

                <div className='page-registration__form'>
                    <div>
                        <DASelect labelText={getCms('subcenter-chooser.location')}
                            optionsValue="Name"
                            optionsKey="Id"
                            value={this.props.selectedCenter ?? null}
                            onChange={(e: any) => this.props.subcenterSelected(e.detail.value)}
                            options={this.props.subcenters}
                            isFormSubmited={this.props.formSubmitted}
                            errorMessage={this.getSubcenterErrorMessage()} />
                    </div>

                    <div>
                    {
                        this.getSelectedSubcenter() &&
                        <div className="subcenter-info">
                            <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.03526 0.5C2.7038 0.5 0 2.9086 0 5.87634C0 8.94086 3.52459 13.629 6.03526 16.5C8.58214 13.6075 12.0705 8.97312 12.0705 5.87634C12.0705 2.9086 9.36673 0.5 6.03526 0.5ZM6.03463 8.56458C4.3689 8.56458 3.017 7.36027 3.017 5.8764C3.017 4.39253 4.3689 3.18823 6.03463 3.18823C7.70036 3.18823 9.05226 4.39253 9.05226 5.8764C9.05226 7.36027 7.70036 8.56458 6.03463 8.56458Z" fill="#FA6966" />
                            </svg>
                            <span className="name">{this.getSelectedSubcenter()!.Name}</span>
                            <div className="address">{this.getFullAddress(this.getSelectedSubcenter()!)}</div>
                        </div>
                    }
                    </div>
                </div>
            </div>
        );
    }
};

export default withRouter(withIonLifeCycle(SubcenterChooser));
