import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from './i18n/LanguageDetector/LanguageDetector';
import { isDev, isMobileDevice } from '../services/PlastformService';

import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/hu';
import 'moment/locale/de';
import 'moment/locale/ru';
import 'moment/locale/uz';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import datepickerCzech from 'date-fns/locale/cs';
import datepickerHu from 'date-fns/locale/hu';
import datepickerDe from 'date-fns/locale/de';
import datepickerRu from 'date-fns/locale/ru';
import datepickerUz from 'date-fns/locale/uz';

export const defaultLanguage: string = 'cs';

export async function initI18n() {
    let detectionOrder = ['localStorage', 'sessionStorage', 'device', 'navigator'];
    // Use 'device' detector on mobiles only
    if (!isMobileDevice()) {
        detectionOrder = detectionOrder.filter(detector => detector !== 'device');
    }

    // Add locales for ReactDatepicker
    registerLocale('cs', datepickerCzech);
    registerLocale('hu', datepickerHu);
    registerLocale('de', datepickerDe);
    registerLocale('ru', datepickerRu);
    registerLocale('uz', datepickerUz);

    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .use(LanguageDetector)
        .init({
            debug: isDev(),
            detection: {
                order: detectionOrder
            },
            // lng: lang, - Rely on Language detector
            nonExplicitSupportedLngs: true,
            fallbackLng: [defaultLanguage],
            interpolation: {
                escapeValue: false // react already safes from xss
            }
        }).then(() => {
            if (i18n.options.debug === true) {
                console.info(`i18next initialized, initialized language: ${i18n.language}, i18next options:`, i18n.options);
            }
            // After i18next is inicialized, set locales
            moment.locale(i18n.language);
            // ReactDatepicker locale
            setDatepickerLocale(i18n.language);
        });
}

export function changeLanguage(lang: string) {
    i18n.changeLanguage(lang);
    moment.locale(lang);
    setDatepickerLocale(lang);
    window.dispatchEvent(new Event('localeChange'));
}

function setDatepickerLocale(lang: string) {
    setDefaultLocale(lang.substring(0, 2));
}

export default i18n;