import React, { KeyboardEvent } from 'react';
import { withIonLifeCycle, IonButton } from '@ionic/react';
import { withRouter } from 'react-router-dom';

interface DAButtonProps {
    color?: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark"
    text?: string
    expand?: "full" | "block"
    onClick: React.MouseEventHandler<HTMLIonButtonElement>
    submitOnEnter?: boolean
    cssClass?: string
    size?: "large" | "default" | undefined
}

class DAButton extends React.Component<DAButtonProps> {
    instance: HTMLIonButtonElement | null;

    constructor(props: DAButtonProps) {
        super(props);
        this.instance = null;
    }
    
    componentDidMount() {
        if (this.props.submitOnEnter) {
            window.addEventListener("keyup", this.keyPressHandler);
        }
    }

    componentWillUnmount() {
        if (this.props.submitOnEnter) {
            window.removeEventListener("keyup", this.keyPressHandler);
        }
    }

    keyPressHandler = (event:KeyboardEvent | any) => {
        if (event.key?.toLowerCase() === "enter") {
            this.instance?.click();
        }
    }

    render() {
        return (
            <IonButton ref={elem => this.instance = elem}
                className={`${this.props.size === "large" && "da-button-large"} ${this.props.cssClass}`.trim()}
                color={this.props.color} 
                expand={this.props.expand ?? "full"} 
                onClick={this.props.onClick}>
                {this.props.text}
                {this.props.children}
            </IonButton>
        );
    }
};

export default withRouter(withIonLifeCycle(DAButton));