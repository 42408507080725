import React from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import DACard from '../Core/DACard';
import RestService from '../../services/RestService';
import { getLoggedUser } from '../../services/UserService';
import { SubcenterDetail } from '../../models/backendResponses/GetSubcenterDetailsResponse';
import Format from '../../helpers/Format';
import { getCms } from '../../services/CmsService';
import MapPoint from '../Svg/MapPoint';

interface CenterLocationProps {

}

interface CenterLocationState {
    address: string | undefined
}

class CenterLocation extends React.Component<CenterLocationProps, CenterLocationState> {
    constructor(props: CenterLocationProps) {
        super(props);

        this.state = {
            address: undefined
        };
    }

    async ionViewWillEnter () {
        
        let subCenterList = await RestService.getSubcenterDetails();
        let userSubcenterId = getLoggedUser()?.MobileUser.DefaultSubcenterId;
        let userSubcenter = subCenterList?.Subcenters.find(subcenter => subcenter.Id === userSubcenterId);

        this.setState({address: this.getAdressFromSubcenterObject(userSubcenter)});
    }

    getAdressFromSubcenterObject(userSubcenter: SubcenterDetail | undefined): string {
        if(userSubcenter) {
            return Format.Address(userSubcenter.AddressStreet, userSubcenter.AddressCity, userSubcenter.AddressPostalCode)
        }
        return "";
    }

    render() {
        if(this.state.address) {
            return (
                <DACard icon={<MapPoint />} title={getCms("dashboard.centerLocationTitle")} smallCard cardClass='center-location dots-bg-start-top'>
                    { this.state.address }
                    <a className='center-location__map-link' href={`https://www.google.com/maps/search/?api=1&query=${this.state.address}`} target='_blank' rel='noreferrer'>{getCms("dashboard.show-map")}</a>
                </DACard>
            );
        }
        return ""
    }
};

export default withRouter(withIonLifeCycle(CenterLocation));