export default class Format {
    
    static Address (street?: string, city?: string, zip?: string, countryId?: string) {
        let address = "";
        let spacer = ", "
        if(street) {
            address += street + spacer;
        }
        if(city) {
            address += city + spacer;
        }
        if(zip) {
            address += zip + spacer;
        }
        if(countryId) {
            address += countryId + spacer;
        }

        //Remove last spacer from string
        return address.slice(0, address.length > spacer.length ? -1 * spacer.length : 0);
    }
}