import React from 'react';

class EnvelopeIcon extends React.Component {
    render() {
        return (
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9856 2.10257L7.32201 5.62752C7.23666 5.69174 7.1336 5.72385 7.03053 5.72385C6.927 5.72385 6.82394 5.69174 6.73859 5.62752L2.07501 2.10257C1.87401 1.95082 1.84183 1.67371 2.00272 1.48414C2.16408 1.295 2.45789 1.26465 2.65842 1.41596L7.03053 4.72055L11.4022 1.41596C11.6032 1.26421 11.8965 1.29456 12.0579 1.48414C12.2188 1.67371 12.1866 1.95082 11.9856 2.10257ZM12.6256 0H1.43296C0.917635 0 0.500244 0.393665 0.500244 0.879699V7.47744C0.500244 7.96304 0.917635 8.35714 1.43296 8.35714H12.6256C13.1404 8.35714 13.5583 7.96304 13.5583 7.47744V0.879699C13.5583 0.393665 13.1404 0 12.6256 0Z" />
            </svg>
        );
    }
}

export default EnvelopeIcon;